import * as React from "react";

import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { PayPalButton } from "react-paypal-button-v2";

import { PROVIDERS } from "../../../../core/config";
import { ProviderProps } from "../View";

const PayPal: React.FC<ProviderProps> = ({
  formRef,
  checkout,
  paymentGatewayConfig,
  setLoadingState,
  processPayment,
}, context) => {
  const i18n = context.linguiPublisher.i18n;
  const alert = useAlert();
  const [token, setToken] = React.useState<string|null>(null);

  const handleApprove = (data, actions) => {
      setToken(data.orderID);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if(token) {
      setLoadingState(true);
      checkout.update({captureAuthorized: 'PayPal'});
      processPayment(token, PROVIDERS.PAYPAL);
      setLoadingState(false);
    } else {
      alert.error({title: i18n._('You should first use one of PayPal buttons')});
    }
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <PayPalButton
        options={{
          clientId: paymentGatewayConfig.find(({field}) => field === "api_key").value,
          currency: checkout.checkout.totalPrice.gross.currency,
        }}
        amount={checkout.checkout.totalPrice.gross.amount}
        currency={checkout.checkout.totalPrice.gross.currency}
        shippingPreference="NO_SHIPPING"
        onApprove={handleApprove}
        onError={error => {
          console.log(error);
          alert.error({title: i18n._('Error while processing request by PayPal')});
        }}
      />
    </form>
  );
};

PayPal.contextTypes = {linguiPublisher: PropTypes.object};

export { PayPal };