import "./scss/index.scss";

import { Trans } from "@lingui/react";

import * as React from "react";
import { useAlert } from "react-alert";

import PropTypes from "prop-types";
import { Button, Form, TextField } from "..";
import { maybe } from "../../core/utils";

import { useRequestPasswordReset } from "@sdk/react/mutations";

import { PASSWORD_RESET_URL } from "../../core/config";

const PasswordResetForm: React.FC = (_, { linguiPublisher: { i18n } }) => {
  const [requestPasswordReset, { data, error, loading }] =  useRequestPasswordReset();
  const alert = useAlert();

  React.useEffect(() => {
    if (data) {
      alert.show(
        { title: i18n._('Email with confirmation link has been sent to you') },
        { type: 'success' }
      );
    }
  }, [data]);

  return (
    <div className="password-reset-form">
      <Trans id="Please provide us your email address so we can share you a link to reset your password" render="p" />
        <Form
          errors={maybe(() => error && error.extraInfo.userInputErrors, [])}
          onSubmit={(event, {email}) => {
            event.preventDefault();
            requestPasswordReset({email, redirectUrl: PASSWORD_RESET_URL});
          }}
        >
          <TextField
            name="email"
            autoComplete="email"
            label={i18n._('Email Address')}
            type="email"
            required
          />
          <div className="password-reset-form__button">
            <Button type="submit" {...loading && {disabled: true}}>
              {loading ? i18n._('Loading') : i18n._('Reset password')}
            </Button>
          </div>
        </Form>
    </div>
  )
};

PasswordResetForm.contextTypes = {linguiPublisher: PropTypes.object};

export default PasswordResetForm;
