import "./scss/index.scss";

import React from "react";
import { RouteComponentProps } from "react-router";

import { PasswordResetForm} from "./PasswordResetForm";
import { TypedUserTokenValidityQuery } from "./queries";

import { Offline, OfflinePlaceholder, Online } from "..";

import { parse as parseQs} from "query-string";

import { Trans } from "@lingui/react";

const PasswordReset: React.FC<RouteComponentProps<{}>> = ({ location }) => (
  <div className="container">
    <Online>
      <div className="password-reset-content">
        <Trans id="Reset your password" render="h3" />
        <TypedUserTokenValidityQuery loaderFull variables={parseQs(location.search)} errorPolicy="all">
          {({ data }) => (
            <>
              {data.userTokenValidity && <PasswordResetForm {...parseQs(location.search)} />}
              {!data.userTokenValidity && <Trans id="This link is unfortunately not valid any more" />}
            </>
          )}
        </TypedUserTokenValidityQuery>
      </div>
    </Online>
    <Offline>
      <OfflinePlaceholder />
    </Offline>
  </div>
);

export default PasswordReset;