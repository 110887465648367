import * as React from "react";

import PropTypes from "prop-types";
import { generatePath, RouteComponentProps } from "react-router";

import { I18n } from "@lingui/react"

import { Steps } from "../../components";
import { CheckoutContext, CheckoutContextInterface, CheckoutStep } from "../../context";

import { Button, Form, TextField} from "../../../components";

import { SaleorAPI } from "@sdk/index";
import { SaleorContext } from "@sdk/react/context";

import { checkoutZipInfo } from "@sdk/queries/checkout";
import { CheckoutZipInfo, CheckoutZipInfoVariables } from "@sdk/queries/types/CheckoutZipInfo";
import { shippingAddressUrl } from "../../routes";

interface FormZipCodeType {
  zipCode?: string;
}

const View: React.FC<RouteComponentProps> = ({ history}, { linguiPublisher: { i18n } }) => {
  const saleor = React.useContext<SaleorAPI>(SaleorContext);
  const { checkout, update: updateCheckout } = React.useContext<CheckoutContextInterface>(CheckoutContext);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const submitHandler = async (evt: React.FormEvent, data: FormZipCodeType) => {
    evt.preventDefault();

    setLoading(true);
    try {
      const response = await saleor.client.query<CheckoutZipInfo, CheckoutZipInfoVariables>({
        query: checkoutZipInfo,
        variables: {zipCode: data.zipCode},
      });
      setErrors([]);
      if (response.data.checkoutZipInfo) {
        updateCheckout({ zipInfo: response.data.checkoutZipInfo });
        history.push(generatePath(shippingAddressUrl));
      }
    } catch (e) {
      let errorMsg = i18n._('Error');
      if (e.graphQLErrors && e.graphQLErrors.length) {
        const graphQLErrors = JSON.parse(e.graphQLErrors[0].message.replace(/'/g, '"'));
        if (graphQLErrors.zipCode) {
          errorMsg = graphQLErrors.zipCode[0];
        }
      }
      setErrors([{field: 'zipCode', message: errorMsg}]);
    }
    setLoading(false);

  };

  return (
    <div className="checkout-zip-code">
      <Steps
        step={CheckoutStep.ZipCode}
        checkout={checkout}
      >
        <I18n>
          {({ i18n }) => (
            <Form<FormZipCodeType> onSubmit={submitHandler} errors={errors} cacheKey="ZipCodeForm">
              <TextField
                    label={i18n._('ZIP Code')}
                    type="text"
                    name="zipCode"
                    autoComplete="postal-code"
                    pattern="\d{5}"
                    required
                />
                <Button type="submit">
                  {loading ? i18n._('Loading') : i18n._('Continue')}
                </Button>
            </Form>
          )}
        </I18n>
      </Steps>
    </div>
  );
};

View.contextTypes = {linguiPublisher: PropTypes.object};

export default View;
