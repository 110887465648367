import { pullAllBy } from "lodash";
import * as React from "react";
import { generatePath } from "react-router-dom";

import { history } from "../../history";

import { cartUrl } from "../../routes";

import { ApolloClient } from "apollo-client";
import { CheckoutContextInterface } from "../../checkout/context";

import {
  CartContext,
  CartInterface,
  CartLine,
  CartLineInterface,
  CartServices,
} from "./context";

import { ProductDetails_product } from "../../views/Product/types/ProductDetails";

import { baseUrl as checkoutUrl } from "../../checkout/routes";

import { SaleorAPI } from "@sdk/index"
import { isTester } from "@sdk/utils";

enum LocalStorageKeys {
  Cart = "cart",
  BoxId = "box-id",
  Services = "services",
}

enum SessionStorageKeys {
  isBoxAssignmentEditingEnabled = "is-box-assignment-editing-enabled",
  isBoxAssignmentNew = 'is-box-assignment-new',
}

interface CartProviderProps {
  checkout: CheckoutContextInterface;
  apolloClient: ApolloClient<any>;
  saleorApi: SaleorAPI;
}

type CartProviderState = CartInterface;

export default class CartProvider extends React.Component<
  CartProviderProps,
  CartProviderState
> {
  constructor(props: CartProviderProps) {
    super(props);

    let boxId = null;
    let lines = [];
    let services = CartServices.both;
    let isBoxAssignmentEditingEnabled = false;
    let isBoxAssignmentNew = true;
    try {
      boxId = JSON.parse(localStorage.getItem(LocalStorageKeys.BoxId)) || null;
      // TODO: Revert when needed
      // services = JSON.parse(localStorage.getItem(LocalStorageKeys.Services)) || CartServices.both;
      services = JSON.parse(localStorage.getItem(LocalStorageKeys.Services))
        || (isTester() ? CartServices.both : CartServices.cleaning);
      lines = JSON.parse(localStorage.getItem(LocalStorageKeys.Cart)) || [];
      isBoxAssignmentEditingEnabled = JSON.parse(
        sessionStorage.getItem(SessionStorageKeys.isBoxAssignmentEditingEnabled)
      ) || false;
      isBoxAssignmentNew = JSON.parse(
        sessionStorage.getItem(SessionStorageKeys.isBoxAssignmentNew)
      ) || false;
    } catch {
      console.error("Failed loading data from local storage");
    }
    this.state = {
      add: this.add,
      addBox: this.addBox,
      boxId,
      changeQuantity: this.changeQuantity,
      clear: this.clear,
      clearErrors: this.clearErrors,
      errors: null,
      getQuantity: this.getQuantity,
      isBoxAssignmentEditing: this.isBoxAssignmentEditing,
      isBoxAssignmentEditingEnabled,
      isBoxAssignmentNew,
      isEnabled: this.isEnabled,
      lines,
      loading: false,
      remove: this.remove,
      services,
      setServices: this.setServices,
      startBoxAssignmentEditing: this.startBoxAssignmentEditing,
      stopBoxAssignmentEditing: this.stopBoxAssignmentEditing,
      subtract: this.subtract,
    };
  }

  getLine = (variantId: string): CartLineInterface =>
    this.state.lines.find(line => line.variantId === variantId);

  changeQuantity = (lines: CartLine[], isFromBox = false) => {
    if(!isFromBox) {
      this.clearBox();
    }
    this.setState(prevState => {
      const updatedLines = [
        ...pullAllBy(prevState.lines, lines, "variantId"),
        ...lines,
      ].filter(({ quantity }) => !!quantity);
      localStorage.setItem(LocalStorageKeys.BoxId, JSON.stringify(this.state.boxId));
      localStorage.setItem(LocalStorageKeys.Cart, JSON.stringify(updatedLines));
      return { lines: updatedLines };
    });
  };

  add = (variantId, quantity = 1) => {
    const line = this.getLine(variantId);
    const newQuantity = line ? line.quantity + quantity : quantity;
    this.changeQuantity([{ variantId, quantity: newQuantity }], false);
  };

  addBox = (box: ProductDetails_product) => {
    this.clear();
    this.setState({ boxId: box.id });

    setTimeout(() => {
      const lines = box.childProductVariants.map(
        child => ({
          quantity: child.quantityInBox,
          variantId: child.variantId,
        })
      );
      this.changeQuantity(lines, true);
    });

    setTimeout(() => history.push(checkoutUrl))
  };

  subtract = (variantId, quantity = 1) => {
    const line = this.getLine(variantId);
    const newQuantity = line ? line.quantity - quantity : quantity;
    this.changeQuantity([{ variantId, quantity: newQuantity }]);
  };

  clearBox = () => {
    this.setState({ boxId: null });
    localStorage.removeItem(LocalStorageKeys.BoxId);
  };

  clear = () => {
    this.clearBox();
    this.setState({ lines: [], errors: [] });
    localStorage.removeItem(LocalStorageKeys.Cart);
  };

  clearErrors = () => this.setState({ errors: [] });

  getQuantity = () =>
    this.state.lines.reduce((sum, line) => sum + line.quantity, 0);

  remove = variantId => this.changeQuantity([{ variantId, quantity: 0 }]);

  setServices = (services: CartServices) => {
    this.setState({ services });
    localStorage.setItem(LocalStorageKeys.Services, JSON.stringify(services));
  };

  startBoxAssignmentEditing = (lines: CartLine[] | null = null) => {
    if (this.isBoxAssignmentEditing()) {
      const isBoxAssignmentNew = !lines;

      this.clear();
      if (lines) {
        this.changeQuantity(lines);
      }
      sessionStorage.setItem(SessionStorageKeys.isBoxAssignmentEditingEnabled, JSON.stringify(true));
      sessionStorage.setItem(SessionStorageKeys.isBoxAssignmentNew, JSON.stringify(isBoxAssignmentNew));
      this.setState({ isBoxAssignmentEditingEnabled: true, isBoxAssignmentNew });
      setTimeout(() => {
        history.push(generatePath(cartUrl, { token: null }))
      });
    }
  };

  stopBoxAssignmentEditing = () => {
    if (this.isBoxAssignmentEditing()) {
      this.clear();
    }
    sessionStorage.setItem(SessionStorageKeys.isBoxAssignmentEditingEnabled, JSON.stringify(false));
    sessionStorage.setItem(SessionStorageKeys.isBoxAssignmentNew, JSON.stringify(true));
    this.setState({ isBoxAssignmentEditingEnabled: false, isBoxAssignmentNew: true });
  };

  isBoxAssignmentEditing = () => this.props.saleorApi.isLoggedIn();

  isEnabled = () => this.isBoxAssignmentEditing()
      ? this.state.isBoxAssignmentEditingEnabled
      : true;

  render() {
    return (
      <CartContext.Provider value={this.state}>
        {this.props.children}
      </CartContext.Provider>
    );
  }
}
