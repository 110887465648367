import "./scss/index.scss";

import * as React from "react";

import { I18n } from "@lingui/react"
import { useSignIn } from "@sdk/react";
import { maybe } from "@utils/misc";

import { Button, Form, TextField } from "..";

interface ILoginForm {
  hide?: () => void;
}

const LoginForm: React.FC<ILoginForm> = ({ hide }) => {
  const [signIn, { loading, error }] = useSignIn();

  const handleOnSubmit = async (evt, { email, password }) => {
    evt.preventDefault();
    const authenticated = await signIn({ email, password });
    if (authenticated && hide) {
      hide();
    }
  };

  return (
    <I18n>
      {({i18n}) =>
        <div className="login-form">
          <Form
            errors={maybe(() => error.extraInfo.userInputErrors, [])}
            onSubmit={handleOnSubmit}
          >
            <TextField
              name="email"
              autoComplete="email"
              label={i18n._('Email Address')}
              type="email"
              required
            />
            <TextField
              name="password"
              autoComplete="password"
              label={i18n._('Password')}
              type="password"
              required
            />
            <div className="login-form__button">
              <Button type="submit" {...(loading && {disabled: true})}>
                {loading ? i18n._('Loading') : i18n._('Sign in')}
              </Button>
            </div>
          </Form>
        </div>
      }
    </I18n>
  );
};

export default LoginForm;
