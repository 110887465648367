import "./scss/index.scss";

import {Trans} from "@lingui/react";
import * as React from "react";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";

import {
  Button,
  Offline,
  OfflinePlaceholder,
  Online,
  Overlay,
  OverlayContextInterface
} from "../..";
import { baseUrl as checkoutUrl } from "../../../checkout/routes";
import { generateCategoryUrl, maybe } from "../../../core/utils";
import { TypedProductVariantsQuery } from "../../../views/Product/queries";
import { CartContext } from "../../CartProvider/context";
import { extractCartLines, getTotal } from "../../CartProvider/utils";
import { Error } from "../../Error";
import Loader from "../../Loader";
import { ShopContext } from "../../ShopProvider/context";
import { TotalContext } from "../../TotalProvider/context";
import Empty from "./Empty";
import ProductList from "./ProductList";

import cartImg from "../../../images/cart.svg";
import closeImg from "../../../images/x.svg";

const Cart: React.FC<{ overlay: OverlayContextInterface }> = ({ overlay }) => {
  return (
    <Overlay context={overlay}>
      <Online>
        <CartContext.Consumer>
          {cart => (
            <ShopContext.Consumer>
              {({ defaultCategory, defaultCountry, geolocalization }) => (
                <TypedProductVariantsQuery
                  displayLoader={false}
                  variables={{ ids: cart.lines.map(line => line.variantId) }}
                  skip={!cart.lines.length}
                  alwaysRender
                >
                  {({ data, loading, error }) => {
                    if (loading) {
                      return (
                        <div className="cart">
                          <Loader full />
                        </div>
                      );
                    }

                    if (error) {
                      return <Error error={error.message} />;
                    }

                    const locale = maybe(
                      () => geolocalization.country.code,
                      defaultCountry.code
                    );
                    return (
                      <div className="cart">
                        <div className="overlay__header">
                          <ReactSVG
                            path={cartImg}
                            className="overlay__header__cart-icon"
                          />
                          <div className="overlay__header-text">
                            <Trans id="My bag" />,{" "}
                            <span className="overlay__header-text-items">
                              {cart.getQuantity() || 0} <Trans id="items" />
                            </span>
                          </div>
                          <ReactSVG
                            path={closeImg}
                            onClick={overlay.hide}
                            className="overlay__header__close-icon"
                          />
                        </div>
                        {cart.lines.length && data ? (
                          <TotalContext.Consumer>
                            {({ canOrderMore, moneyLeft }) => (
                              <>
                                <ProductList
                                  lines={extractCartLines(data, cart.lines, locale)}
                                  remove={cart.remove}
                                />
                                <div className="cart__footer">
                                  <div className="cart__footer__subtotoal">
                                    <span><Trans id="Subtotal" /></span>

                                    <span>
                                      {getTotal(data, cart.lines, locale)}
                                    </span>
                                  </div>

                                  {canOrderMore && (<>
                                    <div className="cart__footer__left">
                                      <Trans id="You have" />
                                      &nbsp;{moneyLeft.toFixed(2)}&nbsp;
                                      <Trans id="&euro; left" /></div>
                                    <div className="cart__footer__button">
                                      <Link
                                        to={generateCategoryUrl(defaultCategory.id, defaultCategory.name)}
                                      >
                                        <Button secondary><Trans id="Add more" /></Button>
                                      </Link>
                                    </div>
                                  </>)}
                                  <div className="cart__footer__button">
                                    <Link to={checkoutUrl}>
                                      <Button><Trans id="Checkout" /></Button>
                                    </Link>
                                  </div>
                                </div>
                              </>
                            )}
                          </TotalContext.Consumer>
                        ) : (
                          <Empty overlayHide={overlay.hide} />
                        )}
                      </div>
                    );
                  }}
                </TypedProductVariantsQuery>
              )}
            </ShopContext.Consumer>
          )}
        </CartContext.Consumer>
      </Online>
      <Offline>
        <div className="cart">
          <OfflinePlaceholder />
        </div>
      </Offline>
    </Overlay>
  );
};

export default Cart;
