import * as React from "react";
import { RouteComponentProps } from "react-router";

import { I18n } from "@lingui/react"
import { IFilters } from "@types";
import { StringParam, useQueryParam } from "use-query-params";
import { MetaWrapper, NotFound, OfflinePlaceholder } from "../../components";
import NetworkStatus from "../../components/NetworkStatus";
import { PRODUCTS_PER_PAGE } from "../../core/config";
import {
  convertSortByFromString,
  convertToAttributeScalar,
  getGraphqlIdFromDBId,
  maybe,
} from "../../core/utils";
import Page from "./Page";
import { TypedCollectionProductsQuery } from "./queries";

type ViewProps = RouteComponentProps<{
  id: string;
}>;

export const FilterQuerySet = {
  encode(valueObj) {
    const str = [];
    Object.keys(valueObj).forEach(value => {
      str.push(value + "_" + valueObj[value].join("_"));
    });
    return str.join(".");
  },

  decode(strValue) {
    const obj = {};
    const propsWithValues = strValue.split(".").filter(n => n);
    propsWithValues.map(value => {
      const propWithValues = value.split("_").filter(n => n);
      obj[propWithValues[0]] = propWithValues.slice(1);
    });
    return obj;
  },
};

export const View: React.FC<ViewProps> = ({ match }) => {
  const [sort, setSort] = useQueryParam("sortBy", StringParam);
  const [attributeFilters, setAttributeFilters] = useQueryParam(
    "filters",
    FilterQuerySet
  );

  const clearFilters = () => {
    setAttributeFilters({});
  };

  const onFiltersChange = (name, value) => {
    if (attributeFilters && attributeFilters.hasOwnProperty(name)) {
      if (attributeFilters[name].includes(value)) {
        if (filters.attributes[`${name}`].length === 1) {
          const att = { ...attributeFilters };
          delete att[`${name}`];
          setAttributeFilters({
            ...att,
          });
        } else {
          setAttributeFilters({
            ...attributeFilters,
            [`${name}`]: attributeFilters[`${name}`].filter(
              item => item !== value
            ),
          });
        }
      } else {
        setAttributeFilters({
          ...attributeFilters,
          [`${name}`]: [...attributeFilters[`${name}`], value],
        });
      }
    } else {
      setAttributeFilters({ ...attributeFilters, [`${name}`]: [value] });
    }
  };

  const filters: IFilters = {
    attributes: attributeFilters,
    pageSize: PRODUCTS_PER_PAGE,
    priceGte: null,
    priceLte: null,
    sortBy: sort || null,
  };
  const variables = {
    ...filters,
    attributes: filters.attributes
      ? convertToAttributeScalar(filters.attributes)
      : {},
    id: getGraphqlIdFromDBId(match.params.id, "Collection"),
    sortBy: convertSortByFromString(filters.sortBy),
  };

  const getSortOptions = i18n => [
    {
      label: i18n._('Clear...'),
      value: null,
    },
    {
      label: i18n._('Price Low-High'),
      value: "price",
    },
    {
      label: i18n._('Price High-Low'),
      value: "-price",
    },
    {
      label: i18n._('Name Increasing'),
      value: "name",
    },
    {
      label: i18n._('Name Decreasing'),
      value: "-name",
    },
    {
      label: i18n._('Last updated Ascending'),
      value: "updated_at",
    },
    {
      label: i18n._('Last updated Descending'),
      value: "-updated_at",
    },
  ];

  return (
    <NetworkStatus>
      {isOnline => (
        <TypedCollectionProductsQuery
          variables={variables}
          errorPolicy="all"
          loaderFull
        >
          {({ loading, data, loadMore }) => {
            const canDisplayFilters = maybe(
              () => !!data.attributes.edges && !!data.collection.name,
              false
            );

            if (canDisplayFilters) {
              const handleLoadMore = () =>
                loadMore(
                  (prev, next) => ({
                    ...prev,
                    products: {
                      ...prev.products,
                      edges: [...prev.products.edges, ...next.products.edges],
                      pageInfo: next.products.pageInfo,
                    },
                  }),
                  { after: data.products.pageInfo.endCursor }
                );

              return (
                <MetaWrapper
                  meta={{
                    description: data.collection.seoDescription,
                    title: data.collection.seoTitle,
                    type: "product.collection",
                  }}
                >
                  <I18n>
                    {({i18n}) => <Page
                      clearFilters={clearFilters}
                      attributes={data.attributes.edges.map(edge => edge.node)}
                      collection={data.collection}
                      displayLoader={loading}
                      hasNextPage={maybe(
                        () => data.products.pageInfo.hasNextPage,
                        false
                      )}
                      sortOptions={getSortOptions(i18n)}
                      activeSortOption={filters.sortBy}
                      filters={filters}
                      products={data.products}
                      onAttributeFiltersChange={onFiltersChange}
                      onLoadMore={handleLoadMore}
                      activeFilters={
                        filters!.attributes
                          ? Object.keys(filters!.attributes).length
                          : 0
                      }
                      onOrder={value => {
                        setSort(value.value);
                      }}
                    />
                    }
                  </I18n>
                </MetaWrapper>
              );
            }

            if (data && data.collection === null) {
              return <NotFound />;
            }

            if (!isOnline) {
              return <OfflinePlaceholder />;
            }
          }}
        </TypedCollectionProductsQuery>
      )}
    </NetworkStatus>
  );
};

export default View;
