import {
  ApolloClient,
  MutationOptions as ApolloMutationOptions
} from "apollo-client";
import gql from "graphql-tag";

import * as Address from "./address";
import * as Auth from "./auth";
import * as BoxAssignments from "./boxAssignments";
import * as Checkout from "./checkout";
import * as User from "./user";

import {
  CreateCheckout,
  CreateCheckoutVariables
} from "./types/CreateCheckout";

import {
  DeactivateBoxAssignment,
  DeactivateBoxAssignmentVariables
} from "./types/DeactivateBoxAssignment";

import {
  completeCheckout,
  completeCheckoutVariables
} from "../../checkout/views/Review/types/completeCheckout";

import {
  DeleteUserAddress,
  DeleteUserAddressVariables
} from "./types/DeleteUserAddress";

import {
  CreateUserAddress,
  CreateUserAddressVariables
} from "./types/CreateUserAddress";

import {
  SetCustomerDefaultAddress,
  SetCustomerDefaultAddressVariables
} from "./types/SetCustomerDefaultAddress";

import {
  UpdateUserAddress,
  UpdateUserAddressVariables
} from "./types/UpdateUserAddress";

import { TokenAuth, TokenAuthVariables } from "./types/TokenAuth";
import {
  UpdateCheckoutBillingAddress,
  UpdateCheckoutBillingAddressVariables
} from "./types/UpdateCheckoutBillingAddress";
import {
  UpdateCheckoutShippingAddress,
  UpdateCheckoutShippingAddressVariables
} from "./types/UpdateCheckoutShippingAddress";

import {
  PasswordChange,
  PasswordChangeVariables
} from "./types/PasswordChange";

import {
  RequestResetPassword,
  RequestResetPasswordVariables
} from "../../components/PasswordResetForm/types/RequestResetPassword"

import { AccountUpdate, AccountUpdateVariables } from "./types/AccountUpdate";

export type MutationOptions<TData, TVariables> = Omit<
  ApolloMutationOptions<TData, TVariables>,
  "mutation"
>;

// TODO: Add ability to pass custom fragments to mutations
export const MUTATIONS = {
  AccountUpdate: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<AccountUpdate, AccountUpdateVariables>
  ) =>
    client.mutate({
      mutation: gql`
        ${User.accountUpdate}
      `,
      ...options,
    }),
  AddressTypeUpdate: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<
      SetCustomerDefaultAddress,
      SetCustomerDefaultAddressVariables
    >
  ) =>
    client.mutate({
      mutation: gql`
        ${Address.setCustomerDefaultAddress}
      `,
      ...options,
    }),
  CompleteCheckout: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<completeCheckout, completeCheckoutVariables>
  ) =>
    client.mutate({
      mutation: gql`
        ${Checkout.completeCheckoutMutation}
      `,
      ...options,
    }),
  CreateCheckout: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<CreateCheckout, CreateCheckoutVariables>
  ) =>
    client.mutate({
      mutation: gql`
        ${Checkout.createCheckoutMutation}
      `,
      ...options,
    }),
  CreateUserAddress: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<CreateUserAddress, CreateUserAddressVariables>
  ) =>
    client.mutate({
      mutation: gql`
        ${Address.createUserAddress}
      `,
      ...options,
    }),
   DeactivateBoxAssignment: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<DeactivateBoxAssignment, DeactivateBoxAssignmentVariables>
  ) => client.mutate({
      mutation: gql`
        ${BoxAssignments.deactivateBoxAssignment}
      `,
      ...options,
    }),
  DeleteUserAddress: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<DeleteUserAddress, DeleteUserAddressVariables>
  ) =>
    client.mutate({
      mutation: gql`
        ${Address.deleteUserAddress}
      `,
      ...options,
    }),
  PasswordChange: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<PasswordChange, PasswordChangeVariables>
  ) =>
    client.mutate({
      mutation: gql`
        ${User.changeUserPassword}
      `,
      ...options,
    }),
  RequestPasswordReset: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<RequestResetPassword, RequestResetPasswordVariables>
  ) =>
    client.mutate({
      mutation: gql`mutation ResetPassword($email: String!, $redirectUrl: String!) {
          requestPasswordReset(email: $email, redirectUrl: $redirectUrl) {
            errors {
              field
              message
            }
          }
        }
      `,
      ...options,
    }),
  TokenAuth: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<TokenAuth, TokenAuthVariables>
  ) =>
    client.mutate({
      mutation: gql`
        ${Auth.tokenAuthMutation}
      `,
      ...options,
    }),
  UpdateCheckoutBillingAddress: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<
      UpdateCheckoutBillingAddress,
      UpdateCheckoutBillingAddressVariables
    >
  ) =>
    client.mutate({
      mutation: gql`
        ${Checkout.updateCheckoutBillingAddressMutation}
      `,
      ...options,
    }),
  UpdateCheckoutShippingAddress: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<
      UpdateCheckoutShippingAddress,
      UpdateCheckoutShippingAddressVariables
    >
  ) =>
    client.mutate({
      mutation: gql`
        ${Checkout.updateCheckoutShippingAddressMutation}
      `,
      ...options,
    }),
  UpdateUserAddress: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<UpdateUserAddress, UpdateUserAddressVariables>
  ) =>
    client.mutate({
      mutation: gql`
        ${Address.updateUserAddress}
      `,
      ...options,
    }),
};

export type MUTATIONS = typeof MUTATIONS;
