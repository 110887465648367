import "./scss/index.scss";

import * as React from "react";

import { I18n } from "@lingui/react";

import { Form, Select, TextField } from "..";

import { ShopContext } from "../ShopProvider/context";
import { FormAddressType, IShippingNewAddressFormProps } from "./types";
import { getFormData } from "./utils";

export const AddNewShippingAddressForm: React.FC<
  IShippingNewAddressFormProps
> = ({ data, errors, onSubmit, children, type }) => (
  <div className="address-form">
    <ShopContext.Consumer>
      {({ countries, geolocalization, defaultCountry }) => (
        <I18n>
          {({i18n}) => <Form<FormAddressType>
            id="new-address-form"
            errors={errors}
            onSubmit={(evt, data) => {
              evt.preventDefault();
              onSubmit(data);
            }}
            data={getFormData(geolocalization, defaultCountry, data)}
          >
            {children}

            <div className="address-form__grid address-form__grid--modal">
              <TextField
                label={i18n._('First Name')}
                type="given-name"
                name="firstName"
                autoComplete="given-name"
                required
              />
              <TextField
                label={i18n._('Last Name')}
                type="family-name"
                name="lastName"
                autoComplete="family-name"
                required
              />
            </div>
            <div className="address-form__grid address-form__grid--modal">
              <TextField
                label={i18n._('Company name (optional)')}
                type="organization"
                name="companyName"
                autoComplete="organization"
              />
              <TextField
                label={i18n._('Phone (Optional)')}
                type="tel"
                name="phone"
                autoComplete="tel"
              />
            </div>
            <div className="address-form__grid address-form__grid--modal unequal">
              <TextField
                label={i18n._('Address line 1')}
                type="address-line1"
                name="streetAddress1"
                autoComplete="address-line1"
                required
              />
              <TextField
                label={i18n._('Address line 2')}
                type="address-line2"
                name="streetAddress2"
                autoComplete="address-line2"
                required
              />
            </div>
            <div className="address-form__grid address-form__grid--modal">
              <TextField
                label={i18n._('ZIP Code')}
                type="postal-code"
                name="postalCode"
                autoComplete="postal-code"
                required
              />
              <TextField
                label={i18n._('City')}
                type="city"
                name="city"
                autoComplete="address-level2"
                required
              />
            </div>
            <div className="address-form__grid address-form__grid--modal">
              <Select
                label={i18n._('Country')}
                name="country"
                options={countries.map(country => ({
                  label: country.country,
                  value: country.code,
                }))}
                autoComplete="country"
              />
              <TextField
                label={i18n._('State/Province (Optional)')}
                type="state"
                name="countryArea"
                autoComplete="address-level1"
              />
            </div>
            <div
              className="address-form__grid--full"
            >
              {type === "shipping" && (
                <TextField
                  label={i18n._('Email Address')}
                  type="email"
                  autoComplete="email"
                  name="email"
                  required
                />
              )}
            </div>
              <label className="checkbox checkbox__bottom">
                <input name="asNew" type="checkbox"/>
                <span>{type === 'shipping' ? i18n._('Use this address as new shipping address')
                  : i18n._('Use this address as new billing address')}</span>
              </label>
          </Form>
          }
        </I18n>
      )}
    </ShopContext.Consumer>
  </div>
);

export default AddNewShippingAddressForm;
