import "./scss/index.scss";

import * as React from "react";
import { RouteComponentProps } from "react-router";

import { Loader } from "@components/atoms";
import { usePackageDetails } from "@sdk/react";

import Page from "./Page";

const View: React.FC<RouteComponentProps<{ id: string }>> = ({
  match : {
    params: { id },
  },
}) => {
  const { data: packageItem, loading } = usePackageDetails({ id });

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="package-details container">
      <Page packageItem={packageItem} />
    </div>
  );
};

export default View;