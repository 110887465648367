import "./scss/index.scss";

import * as React from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";

import { history } from "../../history";
import { boxAssignmentsUrl } from "../../routes";

import { Trans } from "@lingui/react";

import { CheckoutContextInterface } from "../../checkout/context";
import { TypedCreateBoxAssignmentMutation } from "../../checkout/queries";
import { baseUrl as checkoutUrl } from "../../checkout/routes";
import { Button, CartTable, EmptyCart } from "../../components";
import { CartInterface } from "../../components/CartProvider/context";
import {
  extractCartLines,
  getTotal, getTotalRaw
} from "../../components/CartProvider/utils";
import { OverlayContextInterface } from "../../components/Overlay/context";
import { ShopContext } from "../../components/ShopProvider/context";
import { getShop_shop } from "../../components/ShopProvider/types/getShop";
import { generateCategoryUrl, maybe } from "../../core/utils";
import { TypedProductVariantsQuery } from "../Product/queries";

import { MAX_CART_PRICE } from "../../core/config";

interface PageProps {
  checkout: CheckoutContextInterface;
  overlay: OverlayContextInterface;
  cart: CartInterface;
  shop: getShop_shop;
}

const Page: React.FC<PageProps> = ({
  shop: { geolocalization, defaultCountry },
  cart: {
    lines,
    remove,
    add,
    errors,
    clearErrors,
    subtract,
    loading: cartLoading,
    changeQuantity,
    isBoxAssignmentEditing,
    isBoxAssignmentNew,
    isEnabled,
    stopBoxAssignmentEditing,
  },
}) => {
  const alert = useAlert();
  const hasErrors: boolean | null = maybe(() => !!errors.length);

  React.useEffect(() => {
    if (hasErrors) {
      alert.show(
        {
          content: errors.map(err => err.message).join(", "),
          title: "Error",
        },
        { type: "error" }
      );
      clearErrors();
    }
  }, [hasErrors]);

  const productTableProps = {
    add,
    changeQuantity,
    invalid: maybe(() => !!errors.length, false),
    processing: cartLoading,
    remove,
    subtract,
  };
  const locale = maybe(() => geolocalization.country.code, defaultCountry.code);

  return (
    <ShopContext.Consumer>
      {({ defaultCategory, minProductPrice }) => (
        <TypedProductVariantsQuery
          variables={{
            ids: lines.map(line => line.variantId),
          }}
        >
          {({ data }) => {
            const totalRaw = getTotalRaw(data, lines);
            const moneyLeft = MAX_CART_PRICE - totalRaw;
            const canOrderMore = moneyLeft >= minProductPrice;
            const canSubmit = isEnabled() && !cartLoading && !!lines.length;

            return (
              <>
                {!!lines.length && (
                  <CartTable
                    {...productTableProps}
                    lines={extractCartLines(data, lines, locale)}
                    subtotalRaw={totalRaw}
                    subtotal={getTotal(data, lines, locale)}
                  />
                )}
                {!lines.length && <EmptyCart />}
                <div className="cart-page__checkout-action">
                  {canOrderMore && <Link
                    to={generateCategoryUrl(defaultCategory.id, defaultCategory.name)}
                    className="go-to-category"
                  >
                    <Button disabled={cartLoading}>
                      {moneyLeft.toFixed(2)}&nbsp;<Trans id="&euro; left, choose products for this amount" />
                    </Button>
                  </Link>}
                  {!isBoxAssignmentEditing() && (
                    <Link to={checkoutUrl}>
                    <Button disabled={!canSubmit}><Trans id="Proceed to Checkout" /></Button>
                  </Link>
                  )}
                  {isBoxAssignmentEditing() && (
                    <div>
                      <TypedCreateBoxAssignmentMutation
                        onCompleted={({ boxAssignmentCreate: { errors } }) => {
                          if (!errors.length) {
                            stopBoxAssignmentEditing();
                            history.push(boxAssignmentsUrl);
                          } else {
                            alert.show(
                              {
                                content: errors.map(err => err.message).join(", "),
                                title: "Error",
                              },
                              { type: "error" }
                            );
                          }
                        }}
                        onError={() => {
                          alert.show({ title: 'Error' }, { type: "error" });
                        }}
                      >
                        {(createBoxAssignment, { loading: createBoxAssignmentLoading }) => (
                          <Button
                            disabled={!canSubmit}
                            onClick={() => createBoxAssignment({ variables: { input: { items: lines } } })}
                          >
                            {createBoxAssignmentLoading
                              ? <Trans id="Loading" />
                              : (isBoxAssignmentNew
                                ? <Trans id="Create box assignment" />
                                : <Trans id="Save box assignment" />
                              )}
                          </Button>
                        )}
                      </TypedCreateBoxAssignmentMutation>
                      {!isEnabled() && (
                        <div className="cart-page__disable-reason">
                          <Trans id="Click create or edit in &quot;Box assignments&quot; to enable" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}}
        </TypedProductVariantsQuery>
      )}
    </ShopContext.Consumer>
  );
};

export default Page;
