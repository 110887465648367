import { usePackagesByUser } from "@sdk/react";
import React from "react";

import { Button, Loader } from "@components/atoms";
import { PackagesTable } from "@components/molecules";

import * as S from "./styles";
import { IProps } from "./types";

const PACKAGES_PER_PAGE = 10;

export const PackagesHistory: React.FC<IProps> = ({ history }: IProps) => {
  const { data, loading, loadMore } = usePackagesByUser(
    {
      perPage: PACKAGES_PER_PAGE,
    },
    {
      fetchPolicy: "network-only",
    }
  );

  return loading && !data
    ? (<Loader />)
    : (
      <>
        <PackagesTable packages={data!.edges} history={history} />
        {data!.pageInfo.hasNextPage && (
          <S.Wrapper>
            <Button
              onClick={() => {
                loadMore({
                  after: data!.pageInfo.endCursor,
                  perPage: PACKAGES_PER_PAGE,
                });
              }}
            >
              Load more
            </Button>
          </S.Wrapper>
        )}
      </>
    );
};