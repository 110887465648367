import "./scss/index.scss";

import classNames from "classnames";
import React from "react";
import ReactSVG from "react-svg";

import { I18n, Trans } from "@lingui/react";

import { Modal } from "@components/organisms";

import AddressSummary from "../../../components/AddressSummary";
import { AddNewShippingAddressForm } from "../../../components/ShippingAddressForm";
import { Option } from "../../components";
import { IAddressPickerProps } from "../../types";

import plusSvg from "../../../images/plus.svg";

const renderAddressesList = ({
  addresses,
  onAddressSelect,
  selectedAddress,
}: IAddressPickerProps) =>
  addresses.map((address, id) => {
    const isSelected = selectedAddress === address;
    return (
      <div
        key={id}
        onClick={() => {
          onAddressSelect(address);
        }}
        className={classNames("address-picker__address", {
          "address-picker__address--selected": isSelected,
        })}
      >
        <AddressSummary address={address} email={address.email} />
        <I18n>
          {({i18n}) => <Option
            selected={isSelected}
            value={`${id}`}
            label={i18n._('Deliver to this address')}
          />
          }
        </I18n>
      </div>
    );
  });

const renderModalForm = ({
  errors,
  handleAddressAdd,
  hideAddNewModalForm,
  isVisibleModalForm,
  loading,
  type,
}: IAddressPickerProps) => (
  <I18n>
    {({i18n}) => <Modal
      show={isVisibleModalForm}
      title={i18n._('Add New Address')}
      disabled={loading}
      formId="new-address-form"
      hide={hideAddNewModalForm}
      submitBtnText={i18n._('Save')}
      cancelBtnText={i18n._('Cancel')}
    >
      <AddNewShippingAddressForm
        type={type}
        loading={loading}
        errors={errors}
        onSubmit={handleAddressAdd}
      />
    </Modal>
    }
  </I18n>
);

const AddressPicker: React.FC<IAddressPickerProps> = props => (
  <div className="address-picker">
    {renderAddressesList(props)}
    <div
      className="address-picker__address address-picker__address--add-new"
      onClick={props.showAddNewModalForm}
    >
      <div>
        <ReactSVG path={plusSvg} />
        <p><Trans id="Add new address" /></p>
      </div>
    </div>
    {renderModalForm(props)}
  </div>
);

export default AddressPicker;
