import React from "react";
import Media from "react-media";

import { Trans } from "@lingui/react";
import { ThemeContext } from "styled-components";

import { PackageStatus } from 'types/globalTypes';

import * as S from "./styles";
import { IProps } from "./types";

import { PackagesByUser_me_packages_edges_node } from "@sdk/queries/types/PackagesByUser";

import { formatDate } from "src/core/utils";

const header = (isWide: boolean) => (
  <S.HeaderRow>
    <S.Id>
      <Trans id="ID" />
    </S.Id>
    {isWide && (
      <>
        <S.StatusDate>
          <Trans id="Packing" />
        </S.StatusDate>
        <S.StatusDate>
          <Trans id="Packed" />
        </S.StatusDate>
        <S.StatusDate>
          <Trans id="Sent" />
        </S.StatusDate>
      </>
    )}
    {!isWide && (
      <S.Status>
        <Trans id="Status" />
      </S.Status>
    )}
  </S.HeaderRow>
);

const statusDateCell = (pNode: PackagesByUser_me_packages_edges_node, status: PackageStatus) => {
  const statusStr = status.toLowerCase();
  if (pNode.statusTimestamps[statusStr]) {
    const dateFormatted = formatDate(pNode.statusTimestamps[statusStr]);
    const link = pNode.statusLinks[statusStr] || null;
    return (
      <S.StatusDate>
        <div>{dateFormatted}</div>
        {link && (<S.StatusImageLink>
          <a target="_blank" href={link} onClick={e => e.stopPropagation()}>
            {status === PackageStatus.PACKED ? <Trans id="See image" /> : <Trans id="See tracking" />}
          </a>
        </S.StatusImageLink>)}
      </S.StatusDate>
    );
  } else {
    return (
      <S.StatusDate>
        {status === PackageStatus.PACKED ? <Trans id="Not yet sent" /> : <Trans id="Not yet packed" />}
      </S.StatusDate>
    );
  }
};

const getStatusTrans = (status: PackageStatus) => {
  switch (status) {
    case PackageStatus.PACKING:
      return <Trans id="Packing" />;
    case PackageStatus.PACKED:
      return <Trans id="Packed" />;
    case PackageStatus.SENT:
      return <Trans id="Sent" />;
  }
};

export const PackagesTable: React.FC<IProps> = ({ packages, history }: IProps) => {
  const theme = React.useContext(ThemeContext);

  return (
    <div>
      <Media
        query={{
          minWidth: theme.breakpoints.smallScreen,
        }}
      >
        {(matches: boolean) => (
          <>
            <S.Row>{header(matches)}</S.Row>
            {packages && packages.map(pItem => {
              const pNode = pItem.node;
              return (
                <S.Row
                  key={pNode.id}
                  onClick={e => {
                    e.stopPropagation();
                    history.push(`/package/${pNode.id}`);
                  }}
                >
                  <S.Id>{pNode.number}</S.Id>
                  {matches && (
                    <>
                      {statusDateCell(pNode, PackageStatus.PACKING)}
                      {statusDateCell(pNode, PackageStatus.PACKED)}
                      {statusDateCell(pNode, PackageStatus.SENT)}
                    </>
                  )}
                  {!matches && (
                    <S.Status>
                      {getStatusTrans(pNode.status)}
                    </S.Status>
                  )}
                </S.Row>
              );
            })}
            {!packages.length && <S.Info><Trans id="You don't have any packages yet" /></S.Info>}
          </>
        )}
      </Media>
    </div>
  );
};