import { Formik } from "formik";
import React from "react";
import { TextField } from "../TextField";

import { I18n, Trans } from "@lingui/react";

import { Button, ButtonLink } from "@components/atoms";
import * as S from "./styles";

export const AccountUpdateForm: React.FC<{
  handleSubmit: (data: any) => void;
  hide: () => void;
  initialValues: {
    firstName: string;
    lastName: string;
  };
}> = ({ handleSubmit, hide, initialValues }) => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit({
            firstName: values.firstName,
            lastName: values.lastName,
          });
          setSubmitting(false);
        }}
      >
        {({
          handleChange,
          handleSubmit,
          handleBlur,
          values,
          isSubmitting,
          isValid,
        }) => {
          return (
            <I18n>
              {({i18n}) => <S.Form onSubmit={handleSubmit}>
                <S.ContentEditOneLine>
                  <S.ContentExtendInput>
                    <TextField
                      name="firstName"
                      label={i18n._('First Name')}
                      type="text"
                      value={values.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </S.ContentExtendInput>
                  <S.ContentExtendInput>
                    <TextField
                      name="lastName"
                      label={i18n._('Last Name')}
                      type="text"
                      value={values.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </S.ContentExtendInput>
                </S.ContentEditOneLine>
                <S.FormButtons>
                  <ButtonLink type="button" color="secondary" onClick={hide}>
                    <Trans id="Cancel" />
                  </ButtonLink>
                  <Button
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    size="sm"
                  >
                    <Trans id="Save" />
                  </Button>
                </S.FormButtons>
              </S.Form>
              }
            </I18n>
          );
        }}
      </Formik>
    </>
  );
};
