import * as React from "react";

import {Trans} from "@lingui/react";

const EmptyCart: React.FC<{}> = () => (
  <div className="cart-page__empty">
    <h4><Trans id="Yor bag is empty" /></h4>
    <p>
      <Trans id="You haven’t added anything to your bag. We’re sure you’ll find something in our store" />
    </p>
  </div>
);

export default EmptyCart;
