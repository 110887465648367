import {Trans} from "@lingui/react";
import React from "react";

const ForgottenPassword: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <>
    <div className="login__content__password-reminder">
      <p>
        <Trans id="Have you forgotten your password" />?&nbsp;
        <span className="u-link" onClick={onClick}>
          <Trans id="Click Here" />
        </span>
      </p>
    </div>
  </>
);

export default ForgottenPassword;
