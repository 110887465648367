import React from "react";
import { confirmAlert } from 'react-confirm-alert';
import Media from "react-media";
import { Link } from "react-router-dom";

import { defaultTheme } from "@styles";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ThemeContext, ThemeProvider } from "styled-components";

import { useDeactivateBoxAssignment } from "@sdk/react";

import { Trans } from "@lingui/react";

import * as S from "./styles";
import { IProps } from "./types";

import { CartContext } from "../../../../components/CartProvider/context";

import {
  BoxAssignmentsByUser_me_boxAssignments_edges_node,
  BoxAssignmentsByUser_me_boxAssignments_edges_node_items_edges
} from "@sdk/queries/types/BoxAssignmentsByUser";

import { getGraphqlIdFromDBId } from "../../../../core/utils";

import { Button, IconButton } from "@components/atoms";
import { formatDate, generateProductUrl } from "src/core/utils";

const header = (isWide: boolean) => (
  <S.HeaderRow>
    <S.Id>
      <Trans id="ID" />
    </S.Id>
    <S.Products>
      <Trans id="Products" />
    </S.Products>
    <S.Status>
      <Trans id="Status" />
    </S.Status>
    {isWide && (
      <>
        <S.ActivateDate>
          <Trans id="Activation date" />
        </S.ActivateDate>
        <S.DeactivateDate>
          <Trans id="Deactivation date" />
        </S.DeactivateDate>
      </>
    )}
    <S.Controls />
  </S.HeaderRow>
);

const getStatus = (assignment: BoxAssignmentsByUser_me_boxAssignments_edges_node) => {
  if (assignment.isActive) {
    return <Trans id="Active" />
  } else if (assignment.isPending) {
    return <Trans id="Pending" />
  } else {
    return <Trans id="Deactivated" />
  }
};

export const BoxAssignmentsTable: React.FC<IProps> = (props: IProps) => {
  const [assignments, setAssignments] = React.useState(props.assignments);
  React.useEffect(() => { setAssignments(props.assignments) }, props.assignments);

  const theme = React.useContext(ThemeContext);
  const { startBoxAssignmentEditing } = React.useContext(CartContext);
  const [
    deactivateBoxAssignment,
    { loading: deactivateBoxAssignmentLoading },
  ] = useDeactivateBoxAssignment();

  const hasActiveOrPending = assignments.reduce(
    (previous, assignment) =>
      !!(previous || assignment.node.isActive || assignment.node.isPending),
    false
  );

  const doDelete = async (assignment: BoxAssignmentsByUser_me_boxAssignments_edges_node, onComplete: () => void) => {
    onComplete();
    const result = await deactivateBoxAssignment({ id: assignment.id });
    if (result && result.data && result.data.boxAssignment) {
      const updatedAssignments = assignments.map(
        oldAssignment => assignment.id === oldAssignment.node.id
          ? {
            ...oldAssignment,
            node: {
              ...oldAssignment.node,
              ...result!.data!.boxAssignment,
            },
          }
          : oldAssignment
      );
      setAssignments(updatedAssignments);
    }
  };

  const handleCreate = () => {
    startBoxAssignmentEditing(null);
  };

  const handleEdit = (assignment: BoxAssignmentsByUser_me_boxAssignments_edges_node) => {
    const lines = assignment.items!.edges.map(({ data }) => ({
      quantity: data.quantity,
      variantId: getGraphqlIdFromDBId(data.variant_id, 'ProductVariant'),
    }));
    startBoxAssignmentEditing(lines);
  };

  const handleDelete = async (assignment: BoxAssignmentsByUser_me_boxAssignments_edges_node) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ThemeProvider theme={defaultTheme}>
          <div className="modal-dialog">
            <h1><Trans id="Do you really want to deactivate this assignment?" /></h1>
            <div className="modal-dialog__footer">
              <Button onClick={() => doDelete(assignment, onClose)}><Trans id="Delete" /></Button>
              <Button onClick={onClose}><Trans id="Cancel" /></Button>
            </div>
          </div>
        </ThemeProvider>
      ),
    });
  };

  return (
    <Media
        query={{
          minWidth: theme.breakpoints.mediumScreen,
        }}
      >
        {(isWide: boolean) => (
          <>
            {!hasActiveOrPending && !!assignments.length && (<Button onClick={handleCreate}>
              <Trans id="Create box assignment" />
            </Button>)}
            <S.Row>{header(isWide)}</S.Row>
            {assignments && assignments.map(assignmentEdge => {
              const assignment = assignmentEdge.node;

              return (
                <S.Row key={assignment.id}>
                  <S.Id>{assignment.number}</S.Id>
                  <S.Products>
                    <ul>
                    {assignment!.items!.edges.map(itemEdge => {
                      const { data, node: variant } = itemEdge as BoxAssignmentsByUser_me_boxAssignments_edges_node_items_edges;
                      const productUrl = generateProductUrl(variant!.product.id, variant!.product.name);

                      return (
                        <li>
                          <Link to={productUrl}>{variant!.product.name}</Link>
                          &nbsp;x&nbsp;{data.quantity}
                        </li>
                      );
                    })}
                    </ul>
                  </S.Products>
                  <S.Status>{getStatus(assignment)}</S.Status>
                  {isWide && (
                    <>
                      <S.ActivateDate>
                        {assignment.activateDate ? formatDate(assignment.activateDate) : (<>&mdash;</>)}
                      </S.ActivateDate>
                      <S.DeactivateDate>
                        {assignment.deactivateDate ? formatDate(assignment.deactivateDate) : (<>&mdash;</>)}
                      </S.DeactivateDate>
                    </>
                  )}
                  <S.Controls>
                    {!assignment.deactivateDate && (
                      <>
                        {deactivateBoxAssignmentLoading
                          ? <Trans id="Sending" />
                          : (
                            <>
                              <IconButton
                                name="edit"
                                size={16}
                                onClick={() => handleEdit(assignment)}
                              />
                              {assignment.activateDate && <IconButton
                                name="trash"
                                size={16}
                                onClick={() => handleDelete(assignment)}
                              />}
                            </>
                          )
                        }
                      </>
                    )}
                  </S.Controls>
                </S.Row>
              );
            })}
            {!assignments.length && (<S.BottomPanel>
              <Trans id="You don't have any box assignments yet" />
            </S.BottomPanel>)}
            {!(hasActiveOrPending || assignments.length) && (<S.BottomPanel>
              <Button onClick={handleCreate}><Trans id="Create box assignment" /></Button>
            </S.BottomPanel>)}
          </>
        )}
    </Media>
  );
};