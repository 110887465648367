import { useBoxAssignmentsByUser } from "@sdk/react";
import React from "react";

import { Trans } from "@lingui/react";

import * as H from "history";
import * as S from "./styles";

import { Button, Loader } from "@components/atoms";
import { BoxAssignmentsTable } from "@components/molecules";

const ASSIGNMENTS_PER_PAGE = 10;

export const BoxAssignments: React.FC<{ history: H.History }> = ({ history }) => {
  const { data, loading, loadMore } = useBoxAssignmentsByUser(
    {
      perPage: ASSIGNMENTS_PER_PAGE,
    },
    {
      fetchPolicy: "network-only",
    }
  );

  return (
    <>
      {loading && !data
        ? (<Loader/>)
        : (
          <>
            <BoxAssignmentsTable assignments={data!.edges} history={history}/>
            {data!.pageInfo.hasNextPage && (
              <S.Wrapper>
                <Button
                  onClick={() => {
                    loadMore({
                      after: data!.pageInfo.endCursor,
                      perPage: ASSIGNMENTS_PER_PAGE,
                    });
                  }}
                >
                  <Trans id="Load more" />
                </Button>
              </S.Wrapper>
            )}
          </>
        )
      }
    </>
  );
};