/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum AddressTypeEnum {
  BILLING = "BILLING",
  SHIPPING = "SHIPPING",
}

/**
 * An enumeration.
 */
export enum CountryCode {
  AT = "AT",
  BE = "BE",
  BG = "BG",
  CH = "CH",
  CZ = "CZ",
  DE = "DE",
  DK = "DK",
  EE = "EE",
  ES = "ES",
  FI = "FI",
  FR = "FR",
  GB = "GB",
  HU = "HU",
  IT = "IT",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  NL = "NL",
  NO = "NO",
  PL = "PL",
  PT = "PT",
  SE = "SE",
  SI = "SI",
}

export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * An enumeration.
 */
export enum OrderStatus {
  CANCELED = "CANCELED",
  DRAFT = "DRAFT",
  FULFILLED = "FULFILLED",
  PARTIALLY_FULFILLED = "PARTIALLY_FULFILLED",
  UNFULFILLED = "UNFULFILLED",
}

/**
 * An enumeration.
 */
export enum PackageStatus {
  PACKED = "PACKED",
  PACKING = "PACKING",
  REJECTED = "REJECTED",
  SENT = "SENT",
}

/**
 * An enumeration.
 */
export enum PaymentChargeStatusEnum {
  FULLY_CHARGED = "FULLY_CHARGED",
  FULLY_REFUNDED = "FULLY_REFUNDED",
  NOT_CHARGED = "NOT_CHARGED",
  PARTIALLY_CHARGED = "PARTIALLY_CHARGED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
}

export enum ProductOrderField {
  DATE = "DATE",
  MINIMAL_PRICE = "MINIMAL_PRICE",
  NAME = "NAME",
  PRICE = "PRICE",
  PUBLISHED = "PUBLISHED",
  TYPE = "TYPE",
}

export interface AccountInput {
  firstName?: string | null;
  lastName?: string | null;
  defaultBillingAddress?: AddressInput | null;
  defaultShippingAddress?: AddressInput | null;
}

export interface AddressInput {
  salutation?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  companyName?: string | null;
  streetAddress1?: string | null;
  streetAddress2?: string | null;
  city?: string | null;
  cityArea?: string | null;
  postalCode?: string | null;
  country?: CountryCode | null;
  countryArea?: string | null;
  phone?: string | null;
  healthInsurance?: string | null;
  insuranceNumber?: string | null;
  services?: string | null;
}

export interface AttributeInput {
  slug: string;
  value: string;
}

export interface BoxAssignmentCreateInput {
  items: (BoxAssignmentItemInput | null)[];
}

export interface BoxAssignmentItemInput {
  quantity: number;
  variantId: string;
}

export interface CheckoutCreateInput {
  lines: (CheckoutLineInput | null)[];
  email?: string | null;
  signature?: string | null;
  password?: string | null;
  shippingAddress?: AddressInput | null;
  billingAddress?: AddressInput | null;
  services?: string | null;
}

export interface CheckoutLineInput {
  quantity: number;
  variantId: string;
}

export interface PaymentInput {
  gateway: string;
  token: string;
  amount?: any | null;
  billingAddress?: AddressInput | null;
}

export interface ProductOrder {
  field?: ProductOrderField | null;
  attributeId?: string | null;
  direction: OrderDirection;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
