import { Formik } from "formik";
import React from "react";
import { TextField } from "../TextField";

import { I18n, Trans} from "@lingui/react";

import { Button, ButtonLink } from "@components/atoms";
import { IFormError } from "../../atoms/ErrorMessage/types";
import * as S from "./styles";

export const PasswordChangeForm: React.FC<{
  handleSubmit: (data: any) => void;
  hide: () => void;
  error?: IFormError[];
}> = ({ handleSubmit, hide, error }) => {
  const fieldErrors: any = {};

  if (error) {
    error.map(({ field, message }: { field?: string; message?: string }) => {
      if (field && message) {
        fieldErrors[field] = fieldErrors[field]
          ? [...fieldErrors[field], { message }]
          : [{ message }];
      }
    });
  }
  return (
    <>
      <I18n>
        {({i18n}) => <Formik
          initialValues={{
            confirmPassword: "",
            newPassword: "",
            oldPassword: "",
          }}
          onSubmit={(values, {setSubmitting}) => {
            handleSubmit({
              newPassword: values.newPassword,
              oldPassword: values.oldPassword,
            });
            setSubmitting(false);
          }}
          validateOnChange={false}
          validate={values => {
            const errors: {
              oldPassword?: string;
              confirmPassword?: string;
              newPassword?: string;
            } = {};
            if (!values.confirmPassword) {
              errors.confirmPassword = i18n._('Required field');
            }
            if (!values.newPassword) {
              errors.newPassword = i18n._('Required field');
            }
            if (!values.oldPassword) {
              errors.oldPassword = i18n._('Required field');
            }
            if (values.confirmPassword !== values.newPassword) {
              errors.confirmPassword = i18n._('Passwords do not match');
              errors.newPassword = i18n._('Passwords do not match');
            }
            return errors;
          }}
        >
          {({
            handleChange,
            handleSubmit,
            handleBlur,
            values,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => {
            return (
              <S.Form onSubmit={handleSubmit}>
                <TextField
                  name="oldPassword"
                  label={i18n._('Old Password')}
                  type="password"
                  value={values.oldPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={
                    touched.oldPassword && errors.oldPassword
                      ? [{message: errors.oldPassword}]
                      : undefined || fieldErrors!.oldPassword
                  }
                />
                <TextField
                  name="newPassword"
                  label={i18n._('New Password')}
                  type="password"
                  value={values.newPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={
                    touched.newPassword && errors.newPassword
                      ? [{message: errors.newPassword}]
                      : undefined || fieldErrors!.newPassword
                  }
                />
                <TextField
                  name="confirmPassword"
                  label={i18n._('Confirm Password')}
                  type="password"
                  value={values.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={
                    touched.confirmPassword && errors.confirmPassword
                      ? [{message: errors.confirmPassword}]
                      : undefined || fieldErrors!.confirmPassword
                  }
                />
                <S.FormButtons>
                  <ButtonLink type="button" color="secondary" onClick={hide}>
                    <Trans id="Cancel" />
                  </ButtonLink>
                  <Button
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      size="sm"
                  >
                    <Trans id="Save" />
                  </Button>
                </S.FormButtons>
              </S.Form>
            );
          }}
        </Formik>
        }
      </I18n>
    </>
  );
};
