import React from "react";

import {Trans} from "@lingui/react";

import { Link } from "react-router-dom";
import { Button, OverlayTheme, OverlayType } from "..";
import { OverlayContextInterface } from "../Overlay";

const CheckoutAsGuest: React.FC<{
  overlay: OverlayContextInterface;
  checkoutUrl: string;
}> = ({ overlay, checkoutUrl }) => (
  <div className="checkout-login__guest">
    <h3 className="checkout__header"><Trans id="Continue as a guest" /></h3>
    <p>
      <Trans id="If you don’t wish to register an account, don’t worry. You can checkout as a guest. We care about you just as much as any registered user." />
    </p>
    <Link to={checkoutUrl}>
      <Button><Trans id="Continue as a guest" /></Button>
    </Link>

    <p>
      <Trans id="or you can" />{" "}
      <span
        className="u-link"
        onClick={() => overlay.show(OverlayType.register, OverlayTheme.right)}
      >
        <Trans id="create an account" />
      </span>
    </p>
  </div>
);

export default CheckoutAsGuest;
