import { media, styled } from "@styles";

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 5rem;
  border-bottom: 1px solid ${props => props.theme.colors.tableDivider};
`;

export const HeaderRow = styled(Row)`
  color: ${props => props.theme.colors.lightFont};
  cursor: default;
`;

export const Product = styled.div`
  width: 40%;
`;

export const Price = styled.div`
  width: 20%;
`;

export const Quantity = styled.div`
  width: 20%;
  ${media.smallScreen`
     width: 30%;
  `}
`;

export const Total = styled.div`
  width: 20%;
  ${media.smallScreen`
     width: 30%;
  `}
`;

export const TotalRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 5rem;
`;

export const TotalRowLabel = styled.div`
  text-align: left;
  width: 80%;
  text-transform: uppercase;
  font-weight: bold;
  ${media.smallScreen`
     width: 70%;
  `}
`;

export const TotalRowValue = styled.div`
  text-align: center;
  width: 20%;
  ${media.smallScreen`
     width: 30%;
  `}
`;