import { media, styled } from "@styles";

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
  border-bottom: 1px solid ${props => props.theme.colors.tableDivider};
`;

export const HeaderRow = styled(Row)`
  color: ${props => props.theme.colors.lightFont};
  cursor: default;
`;

export const Id = styled.div`
  width: 10%;
  ${media.mediumScreen`
     width: 20%;
  `}
`;

export const Products = styled.div`
  width: 40%;
  text-align: left;
  padding-left: 2px;
  padding-right: 2px;
  ${media.mediumScreen`
     width: 45%;
  `}
`;

export const Status = styled.div`
  width: 10%;
  padding-left: 2px;
  padding-right: 2px;
  ${media.mediumScreen`
     width: 20%;
  `}
`;

export const ActivateDate = styled.div`
  width: 15%;
`;

export const DeactivateDate = styled.div`
  width: 15%;
`;

export const Controls = styled.div`
  width: 10%;
  display: flex;
  justify-content: space-between;
   ${media.mediumScreen`
     width: 15%;
  `}
`;

export const BottomPanel = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 2rem;
`;