import * as React from "react";

import { Trans } from "@lingui/react";

import { packageHistoryUrl } from "@temp/routes";
import { Link } from "react-router-dom";
import { NotFound } from "../../../components";

import { Package_package } from "@sdk/queries/types/Package";
import { PackageStatus } from 'types/globalTypes';

import { PackageItemsTable } from "@components/molecules";

import { formatDate } from "src/core/utils";

const getStatusTrans = (status: PackageStatus) => {
  switch (status) {
    case PackageStatus.PACKING:
      return <Trans id="Packing" />;
    case PackageStatus.PACKED:
      return <Trans id="Packed" />;
    case PackageStatus.SENT:
      return <Trans id="Sent" />;
  }
};

const statusDateDiv = (packageItem: Package_package, status: PackageStatus) => {
  const statusStr = status.toLowerCase();
  if (packageItem.statusTimestamps[statusStr]) {
    const dateFormatted = formatDate(packageItem.statusTimestamps[statusStr]);
    const link = packageItem.statusLinks[statusStr] || null;
    return (
      <div>
        <span className="package-details__status">{getStatusTrans(status)}:</span>
        <span className="package-details__status-date">{dateFormatted}</span>
        {link && (<span className="package-details__status-link">
          <a target="_blank" href={link} onClick={e => e.stopPropagation()}>
            {status === PackageStatus.PACKED ? <Trans id="See image" /> : <Trans id="See tracking" />}
          </a>
        </span>)}
      </div>
    );
  } else {
    return null;
  }
};

const Page: React.FC<{ packageItem: any }> = ({ packageItem }) =>
  packageItem
    ? (
      <>
        <Link className="package-details__link" to={packageHistoryUrl}>
          <Trans id="Go back to Package History" />
        </Link>
        <h3><Trans id="Your package id" />: {packageItem.number}</h3>
        {statusDateDiv(packageItem, PackageStatus.PACKING)}
        {statusDateDiv(packageItem, PackageStatus.PACKED)}
        {statusDateDiv(packageItem, PackageStatus.SENT)}
        <PackageItemsTable packageData={packageItem} />
      </>
    )
    : <NotFound />;

export default Page;