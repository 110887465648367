import { styled } from "@styles";

export const Wrapper = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => `1.1rem ${props.theme.spacing.gutter}`};
  border-bottom: 1px solid ${props => props.theme.colors.light};
  ${({ selected, theme }) =>
    selected && `font-weight: ${theme.typography.boldFontWeight};`}

  &:hover {
    background-color: ${props => props.theme.colors.primaryLight};
    color: ${props => props.theme.colors.primaryDark};
    font-weight: ${props => props.theme.typography.boldFontWeight};
  }
`;
