import React from "react";
import { PasswordResetForm } from "..";

import {Trans} from "@lingui/react";

const ResetPasswordForm: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <>
    <h3 className="checkout__header"><Trans id="Registered user" /></h3>
    <PasswordResetForm />
    <p>
      <span className="u-link" onClick={onClick}>
        <Trans id="Back to login" />
      </span>
    </p>
  </>
);

export default ResetPasswordForm;
