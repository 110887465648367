import "./scss/index.scss";

import * as React from "react";

import { generatePath, Link } from "react-router-dom";

import { I18n, Trans } from "@lingui/react";

import { Money } from "@components/containers";

import { Button, SelectField } from "../../../components";
import { CartInterface } from "../../../components/CartProvider/context";
import { TypedProductVariantsQuery } from "../../../views/Product/queries";
import { Checkout } from "../../types/Checkout";
import Line from "./Line";
// import PromoForm from "./PromoForm";
import Subtotal from "./Subtotal";

import { cartUrl } from "../../../routes";

import { ProductDetails_product } from "../../../views/Product/types/ProductDetails";

import { SelectValue } from "../../../components/SelectField";

const CartSummary: React.FC<{
  boxes: ProductDetails_product[]
  cart: CartInterface;
  checkout: Checkout | null;
}> = ({ boxes, cart: { boxId, lines, addBox }, checkout }) => {
  const boxOptions = boxes.map(box => ({
    label: box.name,
    value: box.id,
  }));
  let selectedBoxOption = boxOptions.find(boxOption => boxOption.value === boxId);
  if (!selectedBoxOption) {
    boxOptions.push(selectedBoxOption = { label: 'Custom', value: null });
  }
  const onSelectBoxChange = (value: SelectValue) => {
    const box = boxes.find(box => box.id === value.value);
    if (box) {
      addBox(box);
    }
  };

  return (
    <div className="cart-summary">
      <I18n>
        {({i18n}) => <SelectField
          label={i18n._('Box')}
          styleType="grey"
          options={boxOptions}
          value={selectedBoxOption}
          onChange={onSelectBoxChange}
        />
        }
      </I18n>
      {!checkout ? (
        <TypedProductVariantsQuery
          variables={{ ids: lines.map(line => line.variantId) }}
        >
          {({ data }) => (
            <>
              {data.productVariants.edges.map(({ node }) => (
                <Line
                  key={node.id}
                  {...node}
                  quantity={
                    lines.find(({ variantId }) => variantId === node.id)
                      .quantity
                  }
                />
              ))}
              <div className="cart-summary__edit">
                <Link to={generatePath(cartUrl, {token: null})}>
                  <Button><Trans id="Edit" /></Button>
                </Link>
              </div>
              <Subtotal checkout={checkout} variants={data} lines={lines} />
            </>
          )}
        </TypedProductVariantsQuery>
      ) : (
        <>
          {checkout.lines.map(({ variant, quantity, id }) => (
            <Line key={id} {...variant} quantity={quantity} />
          ))}
          <Subtotal checkout={checkout} lines={lines} />
          {/*<div className="cart-summary__promo">*/}
          {/*  <div className="cart-summary__promo__price">*/}
          {/*    <h4><Trans id="Promo code" /></h4>*/}
          {/*    {checkout.voucherCode && (<h4>-<Money defaultValue="-" money={checkout.discount} /></h4>)}*/}
          {/*  </div>*/}
          {/*  <PromoForm checkout={checkout} />*/}
          {/*</div>*/}
          <div className="cart-summary__totals last">
            <h4><Trans id="Grand total" /></h4>
            <h4>
              <Money defaultValue="-" money={checkout.totalPrice.gross} />
            </h4>
          </div>
        </>
      )}
    </div>
  );
};

export default CartSummary;
