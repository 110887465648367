import { createContext } from "react";

import { updateCheckoutLine_checkoutLinesUpdate_errors } from "../../core/types/saleor";
import { ProductDetails_product } from "../../views/Product/types/ProductDetails";

import { isTester } from "@sdk/utils";

export enum CartServices {
  both = 'both',
  box = 'box',
  cleaning = 'cleaning',
}

export interface CartLineInterface {
  variantId: string;
  quantity: number;
}

export interface CartLine {
  variantId: string;
  quantity: number;
}

export interface CartInterface {
  boxId: string | null;
  errors: updateCheckoutLine_checkoutLinesUpdate_errors[] | null;
  isBoxAssignmentEditingEnabled: boolean;
  isBoxAssignmentNew: boolean;
  lines: CartLineInterface[];
  loading: boolean;
  services: CartServices;
  add(variantId: string, quantity?: number): void;
  addBox(box: ProductDetails_product): void;
  changeQuantity(lines: CartLine[]): void;
  clear(): void;
  clearErrors(): void;
  isEnabled(): boolean;
  isBoxAssignmentEditing(): boolean;
  getQuantity(): number;
  remove(variantId: string): void;
  setServices(services: CartServices): void,
  startBoxAssignmentEditing(lines: CartLine[] | null): void,
  stopBoxAssignmentEditing(): void,
  subtract(variantId: string, quantity?: number): void;
}

/* tslint:disable:no-empty */
export const CartContext = createContext<CartInterface>({
  add: (variantId, quantity = 1) => {},
  addBox: (box: ProductDetails_product) => {},
  boxId: null,
  changeQuantity: (lines: CartLine[]) => {},
  clear: () => {},
  clearErrors: () => {},
  errors: null,
  getQuantity: () => 0,
  isBoxAssignmentEditing: () => false,
  isBoxAssignmentEditingEnabled: false,
  isBoxAssignmentNew: true,
  isEnabled: () => true,
  lines: [],
  loading: false,
  remove: variantId => {},
  // services: CartServices.both, TODO: Revert when needed
  services: isTester() ? CartServices.both : CartServices.cleaning,
  setServices: (services) => {},
  startBoxAssignmentEditing: () => {},
  stopBoxAssignmentEditing: () => {},
  subtract: (variantId, quantity = 1) => {},
});
/* tslint:enable:no-empty */

CartContext.displayName = "CartContext";
