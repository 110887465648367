import gql from "graphql-tag";

import { userFragment } from "../fragments/auth";
import { orderDetailFragment } from "../fragments/user";

export const orderDetailsByTokenQuery = gql`
  ${orderDetailFragment}
  query OrderByToken($token: UUID!) {
    orderByToken(token: $token) {
      ...OrderDetail
    }
  }
`;

export const packageDetailsQuery = gql`
    query Package($id: ID!) {
        package(id: $id) {
            id
            number
            items {
                edges {
                    data
                    node {
                        product {
                            id
                            thumbnail {
                                alt
                                url
                            }
                        }
                    }
                }
            }
            status
            price
            statusTimestamps
            statusLinks
        }
    }
`;

export const getUserDetailsQuery = gql`
  ${userFragment}
  query UserDetails {
    me {
      ...User
    }
  }
`;
