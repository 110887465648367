import gql from "graphql-tag";

import { checkoutFragment } from "../fragments/checkout";

export const checkoutDetails = gql`
  ${checkoutFragment}
  query CheckoutDetails($token: UUID!) {
    checkout(token: $token) {
      ...Checkout
    }
  }
`;

export const userCheckoutDetails = gql`
  ${checkoutFragment}
  query UserCheckoutDetails {
    me {
      id
      checkout {
        ...Checkout
      }
    }
  }
`;

export const checkoutZipInfo = gql`
  query CheckoutZipInfo($zipCode: String!) {
      checkoutZipInfo(zipCode: $zipCode) {
          zipCode
          city
          region
      }
  }
`;
