import gql from "graphql-tag";

export const packagesByUser = gql`
    query PackagesByUser($perPage: Int!, $after: String) {
        me {
            id
            packages(first: $perPage, after: $after) {
                pageInfo {
                    hasNextPage
                    endCursor
                }
                edges {
                    node {
                        id
                        number
                        status
                        statusTimestamps
                        statusLinks
                    }
                }
            }
        }
    }
`;