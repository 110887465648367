import { queryFactory, queryWithVariablesFactory } from "./useQuery";

export const useProductDetails = queryWithVariablesFactory("getProductDetails");
export const useProductList = queryWithVariablesFactory("getProductList");

export const useUserDetails = queryFactory("getUserDetails");

export const useUserCheckout = queryFactory("getUserCheckout");

export const useCheckoutDetails = queryWithVariablesFactory(
  "getCheckoutDetails"
);

export const useCheckoutZipInfo = queryWithVariablesFactory("getCheckoutZipInfo");

export const useOrderDetails = queryWithVariablesFactory("getOrderDetails");
export const useOrdersByUser = queryWithVariablesFactory("getOrdersByUser");
export const usePackageDetails = queryWithVariablesFactory("getPackageDetails");
export const usePackagesByUser = queryWithVariablesFactory('getPackagesByUser');
export const useBoxAssignmentsByUser = queryWithVariablesFactory('getBoxAssignmentsByUser');

export const useCategoryDetails = queryWithVariablesFactory(
  "getCategoryDetails"
);

export const useAtrributes = queryWithVariablesFactory("getAttributes");
