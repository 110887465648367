import "./scss/index.scss";

import React from "react";
import { generatePath, Link, RouteComponentProps } from "react-router-dom";

import { Trans } from '@lingui/react'

import { Button, NotFound } from "../../components";
import { BASE_URL } from "../../core/config";
import { guestOrderDetailsUrl } from "../../routes";

const View: React.FC<RouteComponentProps> = ({
  history: {
    location: { state },
    replace,
  },
}) => {
  React.useEffect(() => {
    /**
     * Clear router state on leaving the page to ensure view becames unavailable
     * after leaving.
     */
    return () => {
      if (state) {
        replace({ ...location, state: undefined });
      }
    };
  }, []);

  /**
   * Token or id is passed from review page via router state. If it is not
   * present page should not be displayed.
   */
  if (!state) {
    return <NotFound />;
  }
  const orderDetailsRef = state.token ? generatePath(guestOrderDetailsUrl, { token: state.token }) : null;

  return (
    <div className="order-confirmation">
      <h3>
        <Trans id="Thank you for <0/> your order" components={[<br />]} />
      </h3>
      <p className="order-confirmation__info">
        <Trans
          id="We’ve emailed you a order confirmation<0/>and we’ll notify you when order has been<0/>shipped."
          components={[<br />]}
        />
      </p>
      <div className="order-confirmation__actions">
        <Link to={BASE_URL}>
          <Button secondary><Trans id="Continue Shopping" /></Button>
        </Link>
        {orderDetailsRef && <Link to={orderDetailsRef}>
          <Button><Trans id="Order Details" /></Button>
        </Link>}
      </div>
    </div>
  );
};

export default View;
