import { generatePageUrl } from "./utils";

export const BASE_URL = "/";
export const MAX_CART_PRICE = 60;
export const PRODUCTS_PER_PAGE = 20;
export const SUPPORT_EMAIL = "info@ebox.berlin";
export const PASSWORD_RESET_URL = "https://ebox.berlin/password-reset";
export const PROVIDERS = {
  BRAINTREE: "Braintree",
  DUMMY: "Dummy",
  IN_ADVANCE: "Payment in advance",
  PAYPAL: "PayPal",
  STRIPE: "Stripe",
};
export const STATIC_PAGES = [
  {
    label: "Datenschutz",
    url: generatePageUrl("datenschutz"),
  },
  {
    label: "Impressum",
    url: generatePageUrl("impressum"),
  },
];
export const HOME_STATIC_PAGES = [
  {
    id: "5",
    image: require("../images/home-banner-1.png"),
  },
  {
    id: "6",
    image: require("../images/home-banner-2.png"),
  },
  {
    id: "4",
    image: require("../images/home-banner-3.png"),
  },
];
export const SOCIAL_MEDIA = [
  {
    ariaLabel: "facebook",
    href: "https://www.facebook.com/eboxberlin/",
    path: require("../images/facebook-icon.svg"),
  },
  {
    ariaLabel: "instagram",
    href: "",
    path: require("../images/instagram-icon.svg"),
  },
  /*{
    ariaLabel: "twitter",
    href: "https://twitter.com/getsaleor",
    path: require("../images/twitter-icon.svg"),
  },*/
  {
    ariaLabel: "youtube",
    href: "",
    path: require("../images/youtube-icon.svg"),
  },
];
export const META_DEFAULTS = {
  custom: [],
  description: "",

  image: `${window.location.origin}${require("../images/logo.svg")}`,
  title: "ebox-berlin",
  type: "website",
  url: window.location.origin,
};
