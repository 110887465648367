import {Trans} from "@lingui/react";
import * as React from "react";

import { LineI } from "../../../components/CartTable/ProductRow";
import { maybe } from "../../../core/utils";

import noPhotoImg from "../../../images/no-photo.svg";

const Line: React.FC<Omit<LineI, "totalPrice">> = ({
  id,
  product,
  pricing,
  name,
  quantity,
}) => (
  <div key={id} className="cart-summary__product-item">
    <img src={maybe(() => product.thumbnail.url, noPhotoImg)} />
    <div>
      <p className="cart-summary__product-item__price">{pricing.price.gross.localized}</p>
      <p className="cart-summary__product-item__name">{product.name}</p>
      <div className="cart-summary__product-item__details">
        <span>{name ? `(${name})` : null}</span>
        <span><Trans id="Qty" />: {quantity}</span>
      </div>
    </div>
  </div>
);
export default Line;
