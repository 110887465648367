import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { baseUrl } from "../../routes";

import {
  useCreateCheckout,
  useUpdateCheckoutShippingAddress,
  useUserDetails
} from "@sdk/react";

import { CartContext } from "../../../components/CartProvider/context";
import { ShopContext } from "../../../components/ShopProvider/context";
import { maybe } from "../../../core/utils";
import { CheckoutContext, CheckoutContextInterface } from "../../context";
import Page from "./Page";

const View: React.FC<RouteComponentProps<{ token?: string }>> = ({
  history,
  match: {
    params: { token },
  },
}) => {
  const { data: user } = useUserDetails();
  const createCheckout = useCreateCheckout();
  const updateShippingAddress = useUpdateCheckoutShippingAddress();

  const { checkout, update, zipInfo } = React.useContext<CheckoutContextInterface>(CheckoutContext);

  return zipInfo
    ? (
      <ShopContext.Consumer>
        {shop => (
          <CartContext.Consumer>
            {({ lines }) => (
              <Page
                checkoutId={maybe(() => checkout.id, null)}
                checkout={checkout}
                checkoutZipInfo={zipInfo}
                createCheckout={createCheckout}
                shop={shop}
                update={update}
                updateShippingAddress={updateShippingAddress}
                user={user}
                proceedToNextStepData={{
                  history,
                  token,
                  update,
                }}
                lines={lines}
              />
            )}
          </CartContext.Consumer>
        )}
      </ShopContext.Consumer>
    )
    : <Redirect to={baseUrl} />
};

export default View;
