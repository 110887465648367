import { createContext } from "react";

export interface TotalInterface {
  canOrderMore: boolean;
  moneyLeft: number;
  total: number;
}

export const TotalContext = createContext<TotalInterface>({
  canOrderMore: true,
  moneyLeft: 0,
  total: 0,
});

TotalContext.displayName = "TotalContext";