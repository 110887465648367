import React from "react";

import { I18n } from "@lingui/react";

import { InputSelect, TextField } from "@components/molecules";

import * as S from "./styles";
import { PropsWithFormik } from "./types";

interface IAddressFormContentState {
  errors: any[]
}

class AddressFormContent extends React.Component<PropsWithFormik, IAddressFormContentState> {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
    }
  }

  handleInputError(event) {
    const { target: input } = event;

    const errors = this.state.errors.filter(error => error.field !== input.name);
    if (!input.validity.valid) {
      errors.push({ message: input.validationMessage, field: input.name });
    }

    this.setState({errors});
  }

  handleBlur(event) {
    if (this.props.handleBlur) {
      this.props.handleBlur(event);
    }
    this.handleInputError(event);
  }

  handleChange(event) {
    if (this.props.handleChange) {
      this.props.handleChange(event);
    }
    this.handleInputError(event);
  }

  handleInvalid(event) {
    this.handleInputError(event);
    event.preventDefault();
  }

  render() {
    const {
      formRef,
      formId,
      errors,
      handleSubmit,
      values,
      options,
      defaultValue,
      setFieldValue,
    } = this.props;

    const fieldErrors: any = {};
    const stateErrors = [...this.state.errors];

    if (errors) {
      stateErrors.push(...errors)
    }

    stateErrors.map(({ field, message }: { field: string; message: string }) => {
      fieldErrors[field] = fieldErrors[field]
        ? [...fieldErrors[field], { message }]
        : [{ message }];
    });

    const basicInputProps = {
      onBlur: this.handleBlur.bind(this),
      onChange: this.handleChange.bind(this),
      onInvalid: this.handleInvalid.bind(this),
    };

    return (
      <I18n>
        {({i18n}) => <S.AddressForm id={formId} ref={formRef} onSubmit={handleSubmit}>
          <S.Wrapper>
            <S.RowWithTwoCells>
              <TextField
                name="firstName"
                label={i18n._('First Name')}
                value={values!.firstName}
                autoComplete="given-name"
                errors={fieldErrors!.firstName}
                required
                {...basicInputProps}
              />
              <TextField
                name="lastName"
                label={i18n._('Last Name')}
                value={values!.lastName}
                autoComplete="family-name"
                errors={fieldErrors!.lastName}
                required
                {...basicInputProps}
              />
            </S.RowWithTwoCells>
            <S.RowWithTwoCells>
              <TextField
                name="companyName"
                label={i18n._('Company Name (Optional)')}
                value={values!.companyName}
                autoComplete="organization"
                errors={fieldErrors!.companyName}
                {...basicInputProps}
              />
              <TextField
                name="phone"
                label={i18n._('Phone (Optional)')}
                value={values!.phone}
                autoComplete="tel"
                errors={fieldErrors!.phone}
                {...basicInputProps}
              />
            </S.RowWithTwoCells>
            <S.RowWithTwoUnequalCells>
              <TextField
                name="streetAddress1"
                label={i18n._('Address line 1')}
                value={values!.streetAddress1}
                autoComplete="address-line1"
                errors={fieldErrors!.streetAddress1}
                required
                {...basicInputProps}
              />
              <TextField
                name="streetAddress2"
                label={i18n._('Address line 2')}
                value={values!.streetAddress2}
                autoComplete="address-line2"
                errors={fieldErrors!.streetAddress2}
                {...basicInputProps}
              />
            </S.RowWithTwoUnequalCells>
            <S.RowWithTwoCells>
              <TextField
                name="postalCode"
                label={i18n._('ZIP/Postal Code')}
                value={values!.postalCode}
                autoComplete="postal-code"
                errors={fieldErrors!.postalCode}
                required
                {...basicInputProps}
              />
              <TextField
                name="city"
                label={i18n._('City')}
                value={values!.city}
                autoComplete="address-level1"
                errors={fieldErrors!.city}
                required
                {...basicInputProps}
              />
            </S.RowWithTwoCells>
            <S.RowWithTwoCells>
              <InputSelect
                defaultValue={defaultValue}
                label={i18n._('Country')}
                name="country"
                options={options}
                value={
                  values!.country &&
                  options &&
                  options!.find(option => option.code === values!.country!.code)
                }
                onChange={(value: any, name: any) => setFieldValue(name, value)}
                optionLabelKey="country"
                optionValueKey="code"
              />
              <TextField
                name="countryArea"
                label={i18n._('State/Province (Optional)')}
                value={values!.countryArea}
                autoComplete="address-level2"
                errors={fieldErrors!.countryArea}
                {...basicInputProps}
              />
            </S.RowWithTwoCells>
          </S.Wrapper>
        </S.AddressForm>
        }
      </I18n>
    );
  }
}

export {AddressFormContent};