import { media, styled } from "@styles";

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 5rem;
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colors.tableDivider};
`;

export const HeaderRow = styled(Row)`
  color: ${props => props.theme.colors.lightFont};
  cursor: default;
`;

export const Id = styled.div`
  width: 10%;
  ${media.smallScreen`
     width: 50%;
  `}
`;

export const Status = styled.div`
  width: 50%;
`;

export const StatusDate = styled.div`
  width: 30%;
`;

export const StatusImageLink = styled.div``;

export const Info = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 4rem;
`;