import * as React from "react";
import {generatePath, Link} from "react-router-dom";

import { IconButton } from "@components/atoms";
import { CheckoutZipInfo_checkoutZipInfo } from "@sdk/queries/types/CheckoutZipInfo";
import { ShippingOptionSummary } from ".";
import { AddressSummary } from "../../components";
import { CheckoutStep } from "../context";
import { shippingAddressUrl, zipCodeUrl } from "../routes";
import { Checkout } from "../types/Checkout";

import PropTypes from "prop-types"

const getSummary = (
  step: CheckoutStep,
  checkout: Checkout,
  checkoutZipInfo: CheckoutZipInfo_checkoutZipInfo
): React.ReactNode => {
  let summary;

  switch (step) {
    case CheckoutStep.ZipCode:
      summary = (<div className="zip-summary">
        {checkoutZipInfo.zipCode} ({checkoutZipInfo.region})
        <div className="checkout__step__edit-button">
          <IconButton
            name="edit"
            size={22}
            onClick={() => window.location.href = generatePath(zipCodeUrl)}
          />
        </div>
      </div>);
      break;
    case CheckoutStep.ShippingAddress:
      summary = (
        <AddressSummary
          email={checkout.email}
          address={checkout.shippingAddress}
        />
      );
      break;

    case CheckoutStep.BillingAddress:
      summary = (
        <AddressSummary
          email={checkout.email}
          address={checkout.billingAddress}
        />
      );
      break;

    case CheckoutStep.ShippingOption:
      summary = checkout.shippingMethod && (
        <ShippingOptionSummary shippingMethod={checkout.shippingMethod} />
      );
  }

  return summary ? <div className="checkout__content">{summary}</div> : null;
};

const Steps: React.FC<{
  step: CheckoutStep;
  token?: string;
  checkout?: Checkout;
  checkoutZipInfo?: CheckoutZipInfo_checkoutZipInfo;
}> = ({
      checkout,
      step: currentStep,
      token,
      children,
      checkoutZipInfo,
    }, context) => {
  const i18n = context.linguiPublisher.i18n;
  const steps = [
    {
      header: i18n._('Your ZIP Code'),
      path: zipCodeUrl,
      showHeader: true,
      step: CheckoutStep.ZipCode,
    },
    {
      header: i18n._('Address'),
      path: shippingAddressUrl,
      showHeader: false,
      step: CheckoutStep.ShippingAddress,
    },
  ];
  const currentStepIndex = steps.findIndex(({ step }) => step === currentStep);

  return (
    <>
      {steps.map(({ header, showHeader, step, path }, index) => (
        <React.Fragment key={step}>
          {currentStepIndex > index && (
            <>
              <Link
                to={generatePath(path, {
                  token,
                })}
              >
                <div className="checkout__step checkout__step--inactive">
                  <h4 className="checkout__header">{header}</h4>
                </div>
              </Link>
              {getSummary(step, checkout, checkoutZipInfo)}
            </>
          )}
          {currentStep === step && (
            <>
              {showHeader && (<div className="checkout__step">
                <h4 className="checkout__header">{header}</h4>
              </div>)}
              <div className="checkout__content">{children}</div>
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

Steps.contextTypes = {linguiPublisher: PropTypes.object}

export default Steps;
