import "./scss/index.scss";

import * as React from "react";
import SignatureCanvas from 'react-signature-canvas';
import ReactSVG from "react-svg";

import { I18n, Trans } from "@lingui/react"

import { Button, Form, SelectField, TextField } from "..";
import CartSummary from "../../checkout/components/CartSummary";
import { CartContext, CartServices } from "../CartProvider/context";
import { ShopContext } from "../ShopProvider/context";
import { FormAddressType, IShippingAddressFormProps } from "./types";
import { getFormData } from "./utils";

import classNames from "classnames";

import servicesBothIcon from "../../images/services-both.svg";
import servicesBoxIcon from "../../images/services-box.svg";
import servicesCleaningIcon from "../../images/services-cleaning.svg";

import { TypedCheckoutSubscribeToAvailabilityMutation } from "../../checkout/queries";

import { isTester } from "@sdk/utils";

const generatePassword = () => {
    const length = 8;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const n = charset.length;
    let result = '';
    for (let i = 0; i < length; i++) {
        result += charset.charAt(Math.floor(Math.random() * n));
    }
    return result;
};

const ShippingAddressForm: React.FC<IShippingAddressFormProps> = ({
  data,
  checkout,
  checkoutZipInfo,
  buttonText,
  errors,
  loading,
  onSubmit,
  children,
  shippingAsBilling = false,
  type = "shipping",
}) => {
  const [agreeWithTerms, setAgreeWithTerms] = React.useState(false);
  const [city, setCity] = React.useState(checkoutZipInfo ? checkoutZipInfo.city : '');
  const { geolocalization, defaultCountry, boxes } = React.useContext(ShopContext);
  const cart = React.useContext(CartContext);
  const { services, setServices } = cart;
  const [password] = React.useState(generatePassword());
  const [subscribed, setSubscribed] = React.useState(false);
  const [signatureRef, setSignatureRef] = React.useState(null);
  const [signatureIsInvalid, setSignatureIsInvalid] = React.useState(false);

  const formData = getFormData(geolocalization, defaultCountry, data, services) as FormAddressType;
  if (checkoutZipInfo) {
    if (checkoutZipInfo.city !== 'Berlin') {
      formData.services = CartServices.box;
    }
    formData.postalCode = checkoutZipInfo.zipCode;
    formData.countryArea = checkoutZipInfo.region;
  }

  const servicesAvailable = checkoutZipInfo && checkoutZipInfo.city === 'Berlin';

  const validate = (data, callback) => {
    // TODO: Revert when needed
    if (!isTester()) {
      callback(data);
      return;
    }

    let isValid = true;

    if (!signatureRef.isEmpty()) {
      setSignatureIsInvalid(false);
      data.signature = signatureRef.toDataURL();
    } else {
      isValid = false;
      setSignatureIsInvalid(true);
    }

    if (isValid) {
      callback(data);
    }
  };

  return (
    <>
      {!servicesAvailable && (<h4 className="checkout__header">
        <Trans id="Address" />
      </h4>)}
      {servicesAvailable && (
        <div className="services-select">
            <label className={services === CartServices.cleaning && 'active'} htmlFor="serviceCleaning">
              <div>
                <ReactSVG path={servicesCleaningIcon} className="services-select__icon" />
                <span><Trans id="Cleaning" /></span>
              </div>
            </label>
            <label className={services === CartServices.box && 'active'} htmlFor="serviceBox">
              <div>
                <ReactSVG path={servicesBoxIcon} className="services-select__icon" />
                <span><Trans id="Care box" /></span>
              </div>
            </label>
            <label className={services === CartServices.both && 'active'} htmlFor="serviceBoth">
              <div>
                <ReactSVG path={servicesBothIcon} className="services-select__icon" />
                <span><Trans id="Cleaning and care box" /></span>
              </div>
            </label>
        </div>
      )}
      <div className="checkout__grid">
        <div
          className={"checkout__grid__content"}
          style={(!isTester() && (services !== CartServices.cleaning || !servicesAvailable)) ? { filter: 'blur(1.5px)' } : null}
        >
          <I18n>
            {({i18n}) => <div className="address-form">
              <Form<FormAddressType>
                errors={errors}
                onSubmit={(evt, data) => {
                  evt.preventDefault();
                  validate(data, onSubmit);
                }}
                data={formData}
                cacheKey="ShippingAddressForm"
              >
                {children}
                <fieldset disabled={shippingAsBilling}>
                  {servicesAvailable && (
                    <>
                      <input
                        id="serviceCleaning"
                        className="services-radio"
                        type="radio"
                        name="services"
                        value={CartServices.cleaning}
                        checked={services === CartServices.cleaning}
                        onChange={e => setServices(e.currentTarget.value as CartServices)}
                      />
                      <input
                        id="serviceBox"
                        className="services-radio"
                        type="radio"
                        name="services"
                        value={CartServices.box}
                        checked={services === CartServices.box}
                        onChange={e => setServices(e.currentTarget.value as CartServices)}
                      />
                      <input
                        id="serviceBoth"
                        className="services-radio"
                        type="radio"
                        name="services"
                        value={CartServices.both}
                        checked={services === CartServices.both}
                        onChange={e => setServices(e.currentTarget.value as CartServices)}
                      />
                    </>
                  )}
                  <div className="address-form__grid">
                    <div className="address-form__double-cell--right">
                      <SelectField
                        label={i18n._('Salutation')}
                        name="salutation"
                        options={['Herr', 'Frau', 'Herr Dr.', 'Frau Dr.'].map(item => ({ label: item, value: item }))}
                        autoComplete="salutation"
                      />
                      <TextField
                          label={i18n._('First Name')}
                          type="given-name"
                          name="firstName"
                          autoComplete="given-name"
                          required
                      />
                    </div>
                    <TextField
                        label={i18n._('Last Name')}
                        type="family-name"
                        name="lastName"
                        autoComplete="family-name"
                        required
                    />
                  </div>
                  <div className="address-form__grid">
                    <div className="address-form__double-cell--left">
                      <TextField
                          label={i18n._('Street Name')}
                          type="address-line1"
                          name="streetAddress1"
                          autoComplete="address-line1"
                          required
                      />
                      <TextField
                          label={i18n._('House')}
                          type="house"
                          name="house"
                          autoComplete="house"
                          required
                      />
                    </div>
                    <TextField
                        label={i18n._('City')}
                        type="city"
                        name="city"
                        autoComplete="address-level2"
                        value={city}
                        onChange={e => setCity(e.currentTarget.value)}
                        required
                    />
                  </div>
                  <div className="address-form__grid">
                    <TextField
                        label={i18n._('Health insurance')}
                        type="health-insurance"
                        name="healthInsurance"
                        autoComplete="health-insurance"
                        required
                    />
                    <TextField
                        label={i18n._('Insurance number')}
                        type="insurance-number"
                        name="insuranceNumber"
                        autoComplete="insurance-number"
                        required
                    />
                  </div>
                  <div className="address-form__grid">
                    <TextField
                        label={i18n._('Phone')}
                        type="tel"
                        name="phone"
                        autoComplete="phone"
                        required
                    />
                    {type === "shipping" && !data.email && (
                      <TextField
                        label={i18n._('Email Address')}
                        type="email"
                        autoComplete="email"
                        name="email"
                        required
                      />
                     )}
                  </div>
                  {!checkoutZipInfo && (<div className="address-form__grid">
                    <TextField
                      label={i18n._('ZIP Code')}
                      type="postal-code"
                      name="postalCode"
                      autoComplete="postal-code"
                      required
                    />
                    <TextField
                      label={i18n._('State/Province (Optional)')}
                      type="state"
                      name="countryArea"
                      autoComplete="address-level1"
                    />
                  </div>)}
                </fieldset>
                <input name="password" type="hidden" value={password} />
                {/*TODO: Revert when needed*/}
                {isTester() && <>
                  <div className="checkout__content__signature">
                    <span className="input__label"><Trans id="Signature" /></span>
                    <div className={classNames('checkout__content__signature__canvas', {'error': signatureIsInvalid})}>
                      <SignatureCanvas
                        ref={ref => setSignatureRef(ref)}
                        penColor='#21125e'
                        canvasProps={{width: 420, height: 200, onClick: () => setSignatureIsInvalid(false)}}
                      />
                    </div>
                    {signatureIsInvalid && <div><span className="input__error"><Trans id="Signature is required" /></span></div>}
                  </div>
                  <label className="checkbox">
                    <input
                      name="agreeTerms"
                      type="checkbox"
                      checked={agreeWithTerms}
                      onChangeCapture={({ target: { checked } }) => setAgreeWithTerms(checked)}
                    />
                    <span className="checkout__content__terms">
                      <Trans id="I have read the" />&nbsp;
                      <a href="/media/uploads/AGB_schungit_original.pdf" target="_blank">
                        <Trans id="terms and conditions" />
                      </a>&nbsp;
                      <Trans id="and agree." />
                    </span>
                  </label>
                  <Button type="submit" disabled={loading || !agreeWithTerms}>
                    {loading ? i18n._('Loading') : buttonText}
                  </Button>
                </>}
                {/*TODO: Revert when needed*/}
                {!isTester() && <>
                  <label>
                    Entlastung im Alltag (nach §45b SGB XI) bieten wir in Berlin ab 01.10.2020 an.
                  </label>
                  <br />
                  <Button type="submit" disabled={loading}>
                    Unverbindlich anfragen
                  </Button>
                </>}
              </Form>
            </div>
            }
          </I18n>
        </div>
        {services !== 'cleaning' && <div className="checkout__grid__cart-summary" style={!isTester() ? { filter: 'blur(1.5px)' } : null}>
          <CartSummary boxes={boxes} cart={cart} checkout={checkout}/>
        </div>}
        {/*TODO: Revert when needed*/}
        {!isTester() && (services !== CartServices.cleaning || !servicesAvailable) && (
          <>
            {!subscribed && (<div className="checkout__stub">
              <h2>
                {servicesAvailable ? "Im Angebot ab 01.01.2021. " : "Pflegebox im Angebot ab 01.01.2021. "}
              </h2>
              <p>Hinterlassen Sie gerne Ihre Daten und wir geben Ihnen Bescheid sobald der Service funktioniert.</p>
              <I18n>
                {({ i18n }) => (
                  <TypedCheckoutSubscribeToAvailabilityMutation onCompleted={data => {
                    if (data.checkoutSubscribeToAvailability && data.checkoutSubscribeToAvailability.id) {
                      setSubscribed(true);
                    }
                  }}>
                    {(subscribe, { loading: subscribeLoading }) =>
                      (
                        <Form<{ text: string }>
                          onSubmit={(evt, data) => {
                            evt.preventDefault();
                            subscribe({ variables: data });
                          }}
                        >
                          <textarea
                            name="text"
                            placeholder="Ich heiße Herr Mustermann und bin am besten via Telefonnummer: (030) 123123123 erreichbar. Gerne auch via E-Mail: m.mustermann@muster.de"
                            maxLength="500"
                            required
                          >
                          </textarea>
                          <Button type="submit" disabled={loading}>
                            {loading || subscribeLoading ? i18n._('Loading') : i18n._('Subscribe')}
                          </Button>
                        </Form>
                      )
                    }
                  </TypedCheckoutSubscribeToAvailabilityMutation>
                )}
              </I18n>
            </div>)}
            {subscribed && (<div className="checkout__stub-success">
              <h2>Vielen dank!</h2>
              <p>Sobald der Service von uns angeboten wird, werden wir Sie umgehend kontaktieren.</p>
            </div>)}
          </>
        )}
      </div>
    </>
  )
};

export default ShippingAddressForm;
