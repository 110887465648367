import React from "react";
import Media from "react-media";
import { Link } from "react-router-dom";

import { Trans } from "@lingui/react";
import { ThemeContext } from "styled-components";

import * as S from "./styles";
import { IProps } from "./types";

import { Package_package_items_edges } from "@sdk/queries/types/Package";

import { generateProductUrl } from "src/core/utils";

import { Thumbnail } from "@components/molecules";

const header = (isWide: boolean) => (
  <S.HeaderRow>
    <S.Product>
      <Trans id="Product" />
    </S.Product>
    {isWide && (
      <S.Price>
          <Trans id="Price" />
      </S.Price>
    )}
    <S.Quantity>
      <Trans id="Quantity" />
    </S.Quantity>
    <S.Total>
      {isWide ? <Trans id="Total Price" /> : <Trans id="Price" />}
    </S.Total>
  </S.HeaderRow>
);

export const PackageItemsTable: React.FC<IProps> = ({ packageData }: IProps) => {
  const theme = React.useContext(ThemeContext);

  return (
    <div>
      <Media
        query={{
          minWidth: theme.breakpoints.smallScreen,
        }}
      >
        {(matches: boolean) => (
          <>
            <S.Row>{header(matches)}</S.Row>
            {packageData!.items!.edges.map((item: Package_package_items_edges | null) => {
              if (item && item.node) {
                const productUrl = generateProductUrl(item.node.product.id, item.data.name);
                return (
                  <S.Row>
                    <S.Product className="cart-table__thumbnail">
                      <div>
                        {matches && (
                          <>
                            <Link to={productUrl}>
                              <Thumbnail source={item.node.product} />
                            </Link>
                            &nbsp;
                          </>
                        )}
                        <Link to={productUrl}>{item.data.name}</Link>
                      </div>
                    </S.Product>
                    {matches && (<S.Price>
                      {item.data.price} &euro;
                    </S.Price>)}
                    <S.Quantity>
                      {item.data.quantity}
                    </S.Quantity>
                    <S.Total>
                      {(item.data.price * item.data.quantity).toFixed(2)} &euro;
                    </S.Total>
                  </S.Row>
                );
              } else {
                return (<></>);
              }
            })}
            <S.TotalRow>
              <S.TotalRowLabel><Trans id="Total Cost" /></S.TotalRowLabel>
              <S.TotalRowValue>{packageData.price} &euro;</S.TotalRowValue>
            </S.TotalRow>
          </>
        )}
      </Media>
    </div>
  );
};