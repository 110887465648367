import React from "react";

import { i18nMark, Trans } from "@lingui/react";

import { Icon } from "../Icon";
import { Tile } from "../Tile";
import * as S from "./styles";
import { IProps } from "./types";

// Define here possible titles so they can be extracted for translation
export const i18nPool = [
    i18nMark('Add new address'),
    i18nMark('Add new card'),
];

export const AddNewTile: React.FC<IProps> = ({ type, ...props }: IProps) => {
  return (
    <Tile tileType="addNew" {...props}>
      <S.Content>
        <p>
          <Icon size={24} name="plus" />
        </p>
        <p><Trans id={`Add new ${type}`} /></p>
      </S.Content>
    </Tile>
  );
};
