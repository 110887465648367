import gql from "graphql-tag";

import { TypedMutation } from "../../core/mutations";
import { TypedQuery } from "../../core/queries";

import {SetPassword, SetPasswordVariables} from "./types/SetPassword";
import {UserTokenValidity, UserTokenValidityVariables} from "./types/UserTokenValidity";

const userTokenValidityQuery = gql`
  query UserTokenValidity($email: String!, $token: String!) {
    userTokenValidity(email: $email, token: $token)
  }
`;

const setPasswordMutation = gql`
  mutation SetPassword($token: String!, $email: String!, $password: String!) {
    setPassword(token: $token, email: $email, password: $password) {
      token
      errors {
        field
        message
      }
    }
  }
`;

export const TypedUserTokenValidityQuery = TypedQuery<UserTokenValidity, UserTokenValidityVariables>(
    userTokenValidityQuery
);

export const TypedSetPasswordMutation = TypedMutation<SetPassword, SetPasswordVariables>(
    setPasswordMutation
)