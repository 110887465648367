import gql from "graphql-tag";

export const boxAssignmentsByUser = gql`
  query BoxAssignmentsByUser($perPage: Int!, $after: String) {
      me {
          id
          boxAssignments(first: $perPage, after: $after) {
              pageInfo {
                  hasNextPage
                  endCursor
              }
              edges {
                  node {
                      id
                      number
                      items {
                          edges {
                              data
                              node {
                                  product {
                                      id
                                      name
                                  }
                              }
                          }
                      }
                      isPending
                      isActive
                      createdAt
                      activateDate
                      deactivateDate
                  }
              }
          }
      }
  }
`;