import React from "react";

import {Trans} from "@lingui/react";

import { CartInterface } from "../CartProvider/context";
import AddToCartButton from "./AddToCartButton";

import { MAX_CART_PRICE } from "../../core/config";

const AddToCart: React.FC<{
  disabled: boolean;
  cart: CartInterface;
  onSubmit: () => void;
}> = ({ disabled, cart, onSubmit }) => {
  const cartDisabled = !cart.isEnabled();

  return (
    <>
      <AddToCartButton
        className="product-description__action"
        onClick={onSubmit}
        disabled={disabled || cartDisabled}
      >
        {cart.isBoxAssignmentEditing() ? <Trans id="Add to box" /> : <Trans id="Add to basket" />}
      </AddToCartButton>
      {disabled && <div className="product-description__ask">
        <Trans id="Total price must not exceed" />&nbsp;{MAX_CART_PRICE}&euro;
      </div>}
      {cartDisabled && cart.isBoxAssignmentEditing() && <div className="product-description__ask">
        <Trans id="Click create or edit in &quot;Box assignments&quot; to enable" />
      </div>}
    </>
  );
};

export default AddToCart;
