import React from "react";

import { AccountTile } from "./AccountTile";
import { PasswordTile } from "./PasswordTile";

export const AccountTabTiles: React.FC = () => (
  <div>
    <AccountTile />
    <PasswordTile />
  </div>
);
