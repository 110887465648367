import gql from "graphql-tag";

export const deactivateBoxAssignment = gql`
  mutation DeactivateBoxAssignment($id: ID!) {
    boxAssignmentDeactivate(id: $id) {
      boxAssignment {
        isActive
        deactivateDate
      }
    }
  }
`;