import "./scss/index.scss";

import * as React from "react";

import { Loader, MetaWrapper } from "../../components";
import {getGraphqlIdFromDBId, maybe} from "../../core/utils";
import Page from "./Page";
import { TypedHomePageQuery } from "./queries";
import { ProductsList } from "./types/ProductsList";

import {HOME_STATIC_PAGES} from "../../core/config";

const canDisplay = (data: ProductsList) =>
  maybe(() => !!data.shop.homepageCollection && !!data.categories.edges.length);

const View: React.FC = () => (
  <div className="home-page">
    <TypedHomePageQuery
        alwaysRender
        displayLoader={false}
        errorPolicy="all"
        variables={{
            page1Id: getGraphqlIdFromDBId(HOME_STATIC_PAGES[0].id, "Product"),
            page2Id: getGraphqlIdFromDBId(HOME_STATIC_PAGES[1].id, "Product"),
            page3Id: getGraphqlIdFromDBId(HOME_STATIC_PAGES[2].id, "Product"),
        }}
    >
      {({ data, loading }) => {
        if (canDisplay(data)) {
          return (
            <MetaWrapper
              meta={{
                description: data.shop.description,
                title: data.shop.name,
              }}
            >
              <Page
                loading={loading}
                backgroundImage={data.shop.homepageCollection.backgroundImage}
                categories={data.categories}
                staticPages={[data.page1, data.page2, data.page3]}
                shop={data.shop}
              />
            </MetaWrapper>
          );
        }

        return <Loader full />;
      }}
    </TypedHomePageQuery>
  </div>
);

export default View;
