import * as React from "react";

import { CartContext } from "../CartProvider/context";
import { TotalContext } from "./context";

import { TypedProductVariantsQuery } from "../../views/Product/queries";

import { getTotalRaw } from "../../components/CartProvider/utils";
import { ShopContext } from "../../components/ShopProvider/context";
import { MAX_CART_PRICE } from "../../core/config";

const TotalProvider: React.FC = ({ children }) => (
  <ShopContext.Consumer>
    {({ minProductPrice }) => (
      <CartContext.Consumer>
        {({ lines }) => (
          <TypedProductVariantsQuery
            variables={{
              ids: lines.map(line => line.variantId),
            }}
          >
            {({ data }) => {
              const total = getTotalRaw(data, lines);
              const moneyLeft = MAX_CART_PRICE - total;
              const canOrderMore = moneyLeft >= minProductPrice;

              return (
                <TotalContext.Provider value={{
                  canOrderMore,
                  moneyLeft,
                  total,
                }}>
                  {children}
                </TotalContext.Provider>
              );
            }}
          </TypedProductVariantsQuery>
        )}
      </CartContext.Consumer>
    )}
  </ShopContext.Consumer>
);

export default TotalProvider;