import * as React from "react";

import { useCompleteCheckout, useSignIn } from '@sdk/react';

import PropTypes from "prop-types"

import { FormAddressType } from "../../../components";
import { CartContext, CartLineInterface } from "../../../components/CartProvider/context";
import { maybe } from "../../../core/utils";
import {
  GuestAddressForm,
  Steps
} from "../../components";
import { CheckoutContext, CheckoutStep } from "../../context";
import { ICheckoutData, ICheckoutUserArgs } from "../../types";
import { IShippingPageProps } from "./types";

import { CountryCode } from "types/globalTypes";

import { orderConfirmationUrl } from "../../../routes";

const computeCheckoutData = (
  data: FormAddressType,
  lines: CartLineInterface[],
  email?: string
): ICheckoutData => ({
  email: data.email || email,
  shippingAddress: {
    city: data.city,
    companyName: data.companyName,
    country: (data.country.value || data.country.code || data.country) as CountryCode,
    countryArea: data.countryArea,
    firstName: data.firstName,
    healthInsurance: data.healthInsurance,
    insuranceNumber: data.insuranceNumber,
    lastName: data.lastName,
    phone: data.phone,
    postalCode: data.postalCode,
    salutation: data.salutation.value,
    streetAddress1: `${data.streetAddress1} ${data.house}`,
    streetAddress2: data.streetAddress2,
  },
  signature: data.signature || null,
  ...(lines && {
    lines: lines.map(({ quantity, variantId }) => ({
      quantity,
      variantId,
    })),
  }),
});

const Page: React.FC<IShippingPageProps> = ({
  checkoutId,
  checkout,
  checkoutZipInfo,
  createCheckout: [
    create,
    { loading: createCheckoutLoading, error: createCheckoutError },
  ],
  proceedToNextStepData,
  shop,
  user,
  lines,
  update,
  updateShippingAddress: [
    updateAddress,
    { loading: updateAddressLoading, error: updateAddressError },
  ],
}, context) => {
  const [signIn, {}] = useSignIn();
  const [completeCheckout, { loading: completeCheckoutLoading }] = useCompleteCheckout();
  const { clear: clearCheckout } = React.useContext(CheckoutContext);
  const { clear: clearCart } = React.useContext(CartContext);

  const errors = maybe(
    () => createCheckoutError.extraInfo.userInputErrors,
    maybe(() => updateAddressError.extraInfo.userInputErrors, [])
  );
  const loading = createCheckoutLoading || updateAddressLoading || completeCheckoutLoading;
  const email = maybe(() => user.email, null);

  const i18n = context.linguiPublisher.i18n;

  const onSaveShippingAddressHandler = async (formData: FormAddressType) => {
    if (!checkoutId) {
      const data = computeCheckoutData(formData, lines);

      return create({
        checkoutInput: {
          email: data.email,
          lines: data.lines,
          password: formData.password,
          services: formData.services,
          shippingAddress: data.shippingAddress,
          signature: data.signature,
        },
      });
    }
    const data = computeCheckoutData(formData, null, email);
    return updateAddress({
      checkoutId,
      email: data.email,
      services: formData.services,
      shippingAddress: data.shippingAddress,
    });
  };

  const onProceedToNextStep = async (formData: FormAddressType) => {
    const { update, history } = proceedToNextStepData;

    const result = await onSaveShippingAddressHandler(formData);

    const canProceed = !!result;

    if (canProceed) {
      if (formData.password) {
        await signIn({email: formData.email, password: formData.password});
      }
      update({
        checkout: result.data.checkout || checkout,
        shippingAsBilling: maybe(() => formData.asBilling, false),
      });
      const completeResult = await completeCheckout({
        checkoutId: result.data.checkout.id,
        password: formData.password,
      });
      const completeCanProceed = completeResult && completeResult.data && !completeResult.data.errors.length;
      if (completeCanProceed) {
        const token = completeResult.data.orders.length ? completeResult.data.orders[0].token : null;
        history.push({
          pathname: orderConfirmationUrl,
          state: { token },
        });
        clearCheckout();
        clearCart();
      }
    }
  };

  const getShippingProps = (userCheckoutData: ICheckoutUserArgs) => ({
    buttonText: i18n._('Place your order'),
    errors,
    loading,
    proceedToNextStep: onProceedToNextStep,
    ...userCheckoutData,
  });

  const shippingProps = getShippingProps({
    checkout,
    user,
  });

  return (
    <div className="checkout-shipping">
      <Steps
        step={CheckoutStep.ShippingAddress}
        token={proceedToNextStepData.token}
        checkout={checkout}
        checkoutZipInfo={checkoutZipInfo}
      >
        <GuestAddressForm checkoutZipInfo={checkoutZipInfo} {...shippingProps} shop={shop} />
      </Steps>
    </div>
  );
};

Page.contextTypes = {linguiPublisher: PropTypes.object};

export default Page;
