import * as React from "react";

import {I18n, Trans} from "@lingui/react"

import { Button, Form, TextField } from "..";
import { maybe } from "../../core/utils";
import { TypedSetPasswordMutation } from "./queries";

interface IPasswordResetForm {
  token: string;
  email: string;
}

interface IPasswordsMatch {
  value: string;
  confirmValue: string;
  match: boolean;
}

export const PasswordResetForm: React.FC<IPasswordResetForm> = ({token, email}) => {
  const [passwordsMatch, setPasswordsMatch] = React.useState<IPasswordsMatch>(
      {value: "", confirmValue: "", match: true}
  );

  const handleChange = event => {
    const value = (event.target.name === 'password') ? event.target.value : passwordsMatch.value;
    const confirmValue = (event.target.name === 'confirmPassword') ? event.target.value : passwordsMatch.confirmValue;
    const match = (!confirmValue) || (value === confirmValue)

    setPasswordsMatch({value, confirmValue, match});
  };

  return (
    <I18n>
      {({i18n}) =>
        <TypedSetPasswordMutation>
          {(setPassword, {loading, data}) => {
            const errors = maybe(() => data.setPassword.errors, []);
            if(!passwordsMatch.match) {
              errors.push({
                __typename: "Error",
                field: "confirmPassword",
                message: i18n._("Passwords do not match"),
              });
            }

            return (data && data.setPassword.token)
              ? <Trans id="Password has been changed" />
              : (<div className="password-reset-form">
                <Form
                  errors={errors}
                  onSubmit={(event, {password}) => {
                    event.preventDefault();

                    if(passwordsMatch.match) {
                      setPassword({variables: {token, email, password}});
                    }
                  }}
                >
                  <TextField
                    name="password"
                    label={i18n._('New Password')}
                    type="password"
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    name="confirmPassword"
                    label={i18n._('Confirm Password')}
                    type="password"
                    onChange={handleChange}
                    required
                  />
                  <div className="password-reset-form__button">
                    <Button type="submit" {...loading && {disabled: true}}>
                      {loading ? i18n._('Loading') : i18n._('Reset password')}
                    </Button>
                  </div>
                </Form>
              </div>)
          }}
        </TypedSetPasswordMutation>
      }
    </I18n>
  )
};