import "./scss/index.scss";

import {Category_category} from "../../../src//views/Category/types/Category";

import {Trans} from "@lingui/react";
import * as React from "react";
import { Link } from "react-router-dom";

import { Button, ProductListItem } from "..";
import { generateProductUrl } from "../../core/utils";
import Loader from "../Loader";

import { Product } from "../ProductListItem";

interface ProductsListProps {
  displayLoader: boolean;
  hasNextPage: boolean;
  onLoadMore: () => void;
  products: Product[];
  totalCount: number;
  category?: Category_category;
}

export const ProductList: React.FC<ProductsListProps> = ({
  displayLoader,
  hasNextPage,
  onLoadMore,
  products,
  totalCount,
  category,
}) => {
  const hasProducts = !!totalCount;

  return (
    <div className="products-list">
      <div className="products-list__products container">
        {hasProducts ? (
          <>
            <div className="products-list__products__grid">
              {products.map(product => (
                <Link
                  to={generateProductUrl(product.id, product.name)}
                  key={product.id}
                >
                  <ProductListItem product={product} />
                </Link>
              ))}
            </div>
            {category && <div>
              <hr className="seo-separator" />
              <div className="seo-description" dangerouslySetInnerHTML={{__html: category.description}} />
            </div>}
            <div className="products-list__products__load-more">
              {displayLoader ? (
                <Loader />
              ) : (
                hasNextPage && (
                  <Button secondary onClick={onLoadMore}>
                    Load more products
                  </Button>
                )
              )}
            </div>
          </>
        ) : (
          <div className="products-list__products-not-found">
            <Trans id="We couldn't find any product matching these conditions"/>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductList;
